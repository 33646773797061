import { Injectable } from '@angular/core';
import {
  Description,
  PublicService as HttpTenantService,
  TaxiOperationsResponse,
  TenantResponse,
  TenantSummary,
} from '@ecsec/ff-tenant-api-ang9';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  from,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  distinctUntilChanged,
  flatMap,
  map,
  mergeMap,
  shareReplay,
  withLatestFrom,
} from 'rxjs/operators';
import { SALESPERSON, Scope } from './auth.service';
import {
  AnonymousIdentityEvaluation,
  AuthenticatedUserService,
  ScopeEvaluation,
} from './user.service';

export interface TenantSource {
  user: ScopeEvaluation;
  ids: Set<string>;
}

export type HasUserEvaluation<T> = T & {
  user: ScopeEvaluation;
};

export type HasTaxiOperations<T> = T & {
  taxiOperations: TaxiOperationsResponse;
};
export type HasTenantIds<T> = T & {
  ids: Set<string>;
};

export type TenantId = {
  tenantId: string;
};

export type TenantSelection = {
  tenantId: string;
  ids: Set<string>;
};

export interface ChoosableTenant {
  tenant: TenantSummary;
  choose: () => void;
}

export interface TenantChoice {
  tenant: TenantSummary;
  user: ScopeEvaluation;
}
export type ChosenTenantSummary = HasTaxiOperations<
  HasUserEvaluation<HasTenantIds<TenantSummary>>
>;

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  readonly chosenTenant: Observable<
    HasUserEvaluation<TenantSelection> | undefined
  >;
  readonly chosenTenantSummary: Observable<ChosenTenantSummary | undefined>;
  private chosenTenantId = new BehaviorSubject<string | undefined>(undefined);
  readonly myTenants: Observable<
    Array<HasUserEvaluation<ChoosableTenant>> | undefined
  >;
  readonly hasChosenTenant: Observable<boolean>;
  readonly hasMultipleTenants: Observable<boolean>;

  constructor(
    private tenantService: HttpTenantService,
    private userService: AuthenticatedUserService
  ) {
    this.chosenTenant = combineLatest([
      this.availableTenants(),
      this.chosenTenantId.pipe(distinctUntilChanged()),
    ]).pipe(
      mergeMap((evt) => {
        const [ts, chosenTenantId] = evt;
        if (ts === undefined) {
          return of(undefined);
        }
        const hasChosenTenant =
          chosenTenantId !== undefined && ts.ids.has(chosenTenantId);
        if (ts.ids.size >= 1 && !hasChosenTenant) {
          const tenantId = [...ts.ids].pop();
          this.chosenTenantId.next(tenantId);
          return EMPTY;
        } else if (hasChosenTenant && chosenTenantId !== undefined) {
          return of<HasUserEvaluation<TenantSelection>>({
            user: ts.user,
            tenantId: chosenTenantId,
            ids: new Set(ts.ids),
          });
        } else {
          return of(undefined);
        }
      }),
      shareReplay(1)
    );
    this.hasChosenTenant = this.chosenTenant.pipe(
      map((t) => t !== undefined && !!t.tenantId)
    );
    this.chosenTenantSummary = this.chosenTenant.pipe(
      mergeMap((t) => {
        return t === undefined
          ? of(undefined)
          : this.tenantService
              .getTenantSummary(t.tenantId)
              .toPromise()
              .then(async (v) => {
                const taxiOperations = await this.tenantService
                  .getTaxiOperationsById(v.published.taxiOperationsId)
                  .toPromise();
                const result: ChosenTenantSummary = {
                  ...v,
                  taxiOperations,
                  user: t.user,
                  ids: new Set(t.ids),
                };
                return result;
              });
      }),
      shareReplay(1)
    );
    this.myTenants = this.availableTenants().pipe(
      mergeMap((ts) => {
        console.log('Determining summaries');
        if (ts === undefined) {
          return of(undefined);
        }
        const summaries: Array<Promise<
          HasUserEvaluation<ChoosableTenant>
        >> = [];
        for (const tenant of ts.ids) {
          summaries.push(
            this.tenantService
              .getTenantSummary(tenant)
              .toPromise()
              .then((t) => {
                const result: HasUserEvaluation<ChoosableTenant> = {
                  user: ts.user,
                  tenant: t,
                  choose: () => this.chooseTenant(t.id),
                };
                return result;
              })
          );
        }
        return Promise.all(summaries);
      }),
      shareReplay(1)
    );
    this.hasMultipleTenants = this.availableTenants().pipe(
      map((v) => {
        if (!v) {
          return false;
        } else {
          return v.ids.size > 1;
        }
      }),
      distinctUntilChanged()
    );
  }

  private chooseTenant(tenantId: string) {
    console.log('Choosing tenant:', tenantId);
    this.chosenTenantId.next(tenantId);
  }

  availableTenants(): Observable<TenantSource | undefined> {
    return this.userService.user.pipe(
      mergeMap((user) => {
        if (user.identity === 'anonymous') {
          console.log('No tenants');
          return of(undefined);
        } else {
          if (user.overview.has(SALESPERSON)) {
            console.log('All salesperson tenants');
            const tenantIds = this.tenantService
              .getPaginatedPublishedTenants()
              .toPromise()
              .then((v) => new Set(v.tenants.map((t) => t.id)))
              .then((ids) => {
                console.log('Found ids:', ids);
                const r: TenantSource = {
                  user,
                  ids,
                };
                return r;
              });

            return from(tenantIds);
          } else {
            const tenantIds = new Set(user.assignmentsByTenant.keys());
            console.log('All scope based tenants', tenantIds);

            return of<TenantSource>({
              user,
              ids: tenantIds,
            });
          }
        }
      }),
      shareReplay(1)
    );
  }
}
