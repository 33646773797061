import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ClerkService,
  CompaniesData,
  PendingTaxiTicketinfo,
  TaxiTicketInfo,
} from '@ecsec/ff-taxi-api-ang9';

@Component({
  selector: 'app-company-taxi-overview',
  templateUrl: './company-taxi-overview.component.html',
  styleUrls: ['./company-taxi-overview.component.css'],
})
export class CompanyTaxiOverviewComponent implements OnInit {
  constructor(private clerkService: ClerkService) {}

  hightlightStatus: Array<boolean> = [];
  hightlightPendingStatus: Array<boolean> = [];
  hightlightStatusNewTaxi = false;
  selectedTaxi: TaxiTicketInfo | null = null;
  selectedPendingTaxi: PendingTaxiTicketinfo | null = null;

  @Output() onSelectTaxi = new EventEmitter<TaxiTicketInfo | null>();
  @Output()
  onSelectPendingTaxi = new EventEmitter<PendingTaxiTicketinfo | null>();
  @Output() onNewTaxi: EventEmitter<boolean> = new EventEmitter<boolean>();

  taxis: Array<TaxiTicketInfo> = [];
  pendingTaxis: Array<PendingTaxiTicketinfo> = [];

  private _company: CompaniesData | undefined;

  @Input()
  set company(company: CompaniesData | undefined) {
    this._company = company;
    if (company) {
      var calls = Promise.all([
        this.clerkService
          .listTaxisByCompany(company.tenantId, company.id)
          .toPromise(),
        this.clerkService
          .listTaxiRequestsByCompany(company.tenantId, company.id)
          .toPromise(),
      ])
        .then((results) => {
          const [currentTaxis, currentPendingTaxis] = results;
          this.taxis = currentTaxis;
          this.pendingTaxis = currentPendingTaxis;
          this.hightlightStatus = [];
          this.hightlightPendingStatus = [];
          this.hightlightStatusNewTaxi = false;
          this.selectedTaxi = null;
          this.selectedPendingTaxi = null;
          this.onSelectTaxi.emit(null);
          this.onSelectPendingTaxi.emit(null);
        })
        .catch((evt) => {
          console.error(evt);
        });
    } else {
      console.log('Assigning no company.');
    }
  }
  get company(): CompaniesData | undefined {
    return this._company;
  }

  resetTaxiView() {
    this.reset();
  }

  ngOnInit() {}

  showTaxi(taxi: TaxiTicketInfo, idx: number) {
    this.hightlightStatus = [];
    this.hightlightPendingStatus = [];
    this.hightlightStatusNewTaxi = false;
    this.hightlightStatus[idx] = true;
    this.selectedTaxi = taxi;
    this.onSelectTaxi.emit(this.selectedTaxi);
    console.log(taxi, idx);
  }
  showPending(taxi: PendingTaxiTicketinfo, idx: number) {
    this.hightlightStatus = [];
    this.hightlightPendingStatus = [];
    this.hightlightStatusNewTaxi = false;
    this.hightlightPendingStatus[idx] = true;
    this.selectedPendingTaxi = taxi;
    this.onSelectPendingTaxi.emit(this.selectedPendingTaxi);
    console.log('showPending', taxi, idx);
  }

  newTaxi() {
    this.hightlightStatus = [];
    this.hightlightPendingStatus = [];
    this.hightlightStatusNewTaxi = true;
    this.selectedTaxi = null;
    this.selectedPendingTaxi = null;
    this.onNewTaxi.emit(true);
  }

  reset() {
    this.hightlightStatus = [];
    this.hightlightPendingStatus = [];
    this.hightlightStatusNewTaxi = false;
    this.selectedTaxi = null;
    this.selectedPendingTaxi = null;

    this.ngOnInit();
  }
}
