import { Injectable, OnDestroy } from '@angular/core';
import {
  FeatureCollection,
  GeoJsonObject,
  Geometry,
  GeometryCollection,
} from 'geojson';
import { Observable, of, Subject } from 'rxjs';
import { PublicService as PublicTenantService } from '@ecsec/ff-tenant-api-ang9';
import { ChosenTenantSummary, TenantService } from './tenant.service';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  retryWhen,
  shareReplay,
  takeUntil,
} from 'rxjs/operators';
import { isNotNullOrUndefined } from './util/inspection';
import { genericRetryStrategy } from './util/generic-retry-strategy';

export const FIVE_SECONDS_IN_MILLISECONDS = 1000 * 5;

export interface NamedBorder {
  name: 'Fahrtgrenze' | 'Registrierungsgrenze';
  border: Promise<GeoJsonObject | 'server-error'>;
}

export interface BorderedTenant {
  tenant: ChosenTenantSummary;
  borders: Array<NamedBorder>;
}

@Injectable({ providedIn: 'root' })
export class BorderService implements OnDestroy {
  readonly unsubscribe = new Subject();
  readonly world = [
    [-180, 90],
    [180, 90],
    [180, -90],
    [-180, -90],
  ];
  readonly borderedTenant: Observable<BorderedTenant>;

  constructor(
    private mapService: PublicTenantService,
    private tenantService: TenantService
  ) {
    this.borderedTenant = this.tenantService.chosenTenantSummary.pipe(
      filter(isNotNullOrUndefined),
      distinctUntilChanged(),
      map((tenant) => {
        const borders: Array<NamedBorder> = [];
        if (tenant.published.borderId) {
          borders.push({
            name: 'Fahrtgrenze',
            border: this.fetchBorder(tenant.published.borderId),
          });
        }
        if (tenant.published.registrationBorderId) {
          borders.push({
            name: 'Registrierungsgrenze',
            border: this.fetchBorder(tenant.published.registrationBorderId),
          });
        }

        return {
          tenant,
          borders,
        };
      }),
      takeUntil(this.unsubscribe),
      shareReplay(1)
    );
  }

  private fetchBorder(
    borderId: string
  ): Promise<GeoJsonObject | 'server-error'> {
    return this.mapService
      .getBorderDownloadById(borderId)
      .pipe(
        map((border) => {
          const geoJson: GeoJsonObject = border;
          if (geoJson.type === 'FeatureCollection') {
            const firstGeometry = (geoJson as FeatureCollection).features[0]
              .geometry;
            this.addWorldToInvert(firstGeometry);
          } else if (geoJson.type === 'GeometryCollection') {
            const firstGeometry = (geoJson as GeometryCollection).geometries[0];
            this.addWorldToInvert(firstGeometry);
          }
          return border;
        }),
        retryWhen(
          genericRetryStrategy({
            maxRetryAttempts: 10,
            scalingDurationMilliseconds: FIVE_SECONDS_IN_MILLISECONDS,
          })
        ),
        catchError((err) => {
          console.error(`Could not fetch border ${borderId}:`, err);

          return of<'server-error'>('server-error');
        })
      )
      .toPromise();
  }

  private addWorldToInvert(firstGeometry: Geometry) {
    if (firstGeometry.type === 'MultiPolygon') {
      firstGeometry.coordinates[0].splice(0, 0, this.world);
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
  }
}
