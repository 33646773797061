import { Component, Input, OnInit } from '@angular/core';

import { ClerkService, PassengerResponse } from '@ecsec/ff-passenger-api-ang9';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TenantService } from '../../../tenant.service';
import { isNotUndefined } from '../../../util/inspection';

export interface ViewState {
  hasSelected: boolean;
  selectedUser: PassengerResponse | null;
  info_msg: string | null;
}

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css'],
})
export class UserSettingsComponent implements OnInit {
  tenantId$!: Observable<string>;
  state$: BehaviorSubject<ViewState> = new BehaviorSubject<ViewState>({
    hasSelected: false,
    info_msg: null,
    selectedUser: null,
  });

  constructor(
    private passengerService: ClerkService,
    private tenantService: TenantService
  ) {}

  ngOnInit() {
    this.tenantId$ = this.tenantService.chosenTenant.pipe(
      filter(isNotUndefined),
      map((v) => v.tenantId)
    );
  }

  selectUser(user: PassengerResponse) {
    this.state$.next({
      hasSelected: true,
      info_msg: null,
      selectedUser: user,
    });
  }

  unselect() {
    this.state$.next({
      hasSelected: false,
      info_msg: null,
      selectedUser: null,
    });
  }

  update(selectedUser: PassengerResponse, tenantId: string) {
    console.log('UPDATE');
    if (!selectedUser || selectedUser.id === undefined) {
      return;
    }
    this.passengerService
      .updateOverridesForPassenger(selectedUser.id, tenantId, {
        overrideAgeCheck: selectedUser.overrideAgeCheck,
        overrideResidenceCheck: selectedUser.overrideResidenceCheck,
        blocked: selectedUser.blocked,
      })
      .toPromise()
      .then((evt) => {
        this.state$.next({
          hasSelected: true,
          selectedUser,
          info_msg: 'Update erfolgreich!',
        });
      })
      .catch((evt) => {
        console.error(evt);
        this.state$.next({
          hasSelected: true,
          selectedUser,
          info_msg: 'Update konnte nicht durchgeführt werden!',
        });
      });
  }

  revoke(selectedUser: PassengerResponse, tenantId: string) {
    console.log('REVOKE');
    if (!selectedUser || selectedUser.id === undefined) {
      return;
    }
    this.passengerService
      .updateOverridesForPassenger(selectedUser.id, tenantId, {
        overrideAgeCheck: selectedUser.overrideAgeCheck,
        overrideResidenceCheck: selectedUser.overrideResidenceCheck,
        blocked: true,
      })
      .toPromise()
      .then((evt) => {
        this.state$.next({
          hasSelected: true,
          selectedUser: {
            ...selectedUser,
            blocked: true,
          },
          info_msg: 'eTicket erfolgreich widerrufen!',
        });
      })
      .catch((evt) => {
        console.error(evt);
        this.state$.next({
          hasSelected: true,
          selectedUser,
          info_msg: 'eTicket konnte nicht widerrufen werden!',
        });
      });
  }

  unblock(selectedUser: PassengerResponse, tenantId: string) {
    console.log('UNBLOCK');
    if (selectedUser) {
      selectedUser.blocked = false;
      this.update(selectedUser, tenantId);
    }
  }

  delete(selectedUser: PassengerResponse, tenantId: string) {
    console.log('DELETE');
    if (!selectedUser) {
      return;
    }
    this.passengerService
      .deletePassengerForTenant(selectedUser.id, tenantId)
      .toPromise()
      .then(() => {
        this.state$.next({
          hasSelected: false,
          selectedUser: null,
          info_msg: 'Nutzer wurde erfolgreich gelöscht!',
        });
      })
      .catch((evt: any) => {
        console.error(evt);
        this.state$.next({
          hasSelected: true,
          selectedUser,
          info_msg: 'Nutzer konnte nicht gelöscht werden!',
        });
      });
  }
}
