<div class="row">
  <div class="col-sm-7">
    <div class="row">
      <div class="col-sm-10">
        <div>
          <div class="journey-overview">
            <app-journey-overview
              [journey]="journey"
              [detail]="true"
              [taxiOperations]="(tenantSummary$ | async)?.taxiOperations"
              (priceChanged)="update($event)"
              (positionClick)="zoomMapTo($event)"
            ></app-journey-overview>
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <div
          style="height: 600px;"
          leaflet
          [leafletOptions]="options"
          [leafletLayers]="layers"
          (leafletMapReady)="onMapReady($event)"
        ></div>
      </div>

      <div class="col-sm-12">
        <div>
          <div class="row">
            <div class="col-sm-12">
              Positionen:
            </div>

            <div
              class="col-sm-6 clickable"
              (click)="
                zoomMapTo(
                  journey?.validationReport?.userStartReport?.location
                    ?.latitude,
                  journey?.validationReport?.userStartReport?.location
                    ?.longitude
                )
              "
            >
              <img src="assets/report/startLocation.png" class="hue-rotate0" />
              Fahrgast Start-Position
            </div>
            <div
              class="col-sm-6 clickable"
              (click)="
                zoomMapTo(
                  journey?.validationReport?.userStopReport?.location?.latitude,
                  journey?.validationReport?.userStopReport?.location?.longitude
                )
              "
            >
              <img src="assets/report/stopLocation.png" class="hue-rotate0" />
              Fahrgast Stopp-Position
            </div>

            <div
              class="col-sm-6 clickable"
              (click)="
                zoomMapTo(
                  journey?.validationReport?.taxiStartReport?.location
                    ?.latitude,
                  journey?.validationReport?.taxiStartReport?.location
                    ?.longitude
                )
              "
            >
              <img
                src="assets/report/startLocation_2.png"
                class="hue-rotate1"
              />
              Taxi Start-Position
            </div>
            <div
              class="col-sm-6 clickable"
              (click)="
                zoomMapTo(
                  journey?.validationReport?.taxiStopReport?.location?.latitude,
                  journey?.validationReport?.taxiStopReport?.location?.longitude
                )
              "
            >
              <img src="assets/report/stopLocation_2.png" class="hue-rotate1" />
              Taxi Stopp-Position
            </div>
          </div>
        </div>
        <div
          class="spacing-top"
          *ngIf="
            journey?.validationReport?.borderIncidentReport?.incidentEntries
              ?.length > 0
          "
        >
          Landkreisgrenzüberschreitungen:
          <p
            *ngFor="
              let entry of journey.validationReport.borderIncidentReport
                .incidentEntries;
              let i = index
            "
          >
            <span>
              <img
                src="assets/report/leave.png"
                class="hue-rotate{{ i + 2 }}"
              />
            </span>
            um {{ entry.time | date: 'HH:mm' }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!--
  <div class="col-sm-6" *ngIf="selectedSubReport">
    <form>
      <div class="form-group row">
        <label class="col-sm-3">Detail:</label>
        <textarea class="form-control col-sm-9" type="text" name="selectedSubReport.message" [(ngModel)]="selectedSubReport.message"
          disabled></textarea>
      </div>

      <div class="form-group row">
        <label class="col-sm-3">Fehlecode:</label>
        <input class="form-control col-sm-9" type="text" name="selectedSubReport.minor" [(ngModel)]="selectedSubReport.minor" disabled>
      </div>

    </form>
  </div> -->

  <div class="col-sm-5">
    <div class="col-sm-12">
      <form>
        <div class="form-group row">
          <label class="col-sm-4">Fahrt gültig?:</label>
          <div class="checkboxFive">
            <input
              id="validationCheck"
              type="checkbox"
              [disabled]="
                userMessageModel.invalid ||
                !journey ||
                (!journey?.validationReport?.result?.userMessage &&
                  journey.validationReport.result.major.endsWith('ok'))
              "
              [checked]="
                journey.validationReport.result.major ===
                'journey:validation:ok'
              "
              (change)="
                journey.validationReport.result.major = $event.target.checked
                  ? 'journey:validation:ok'
                  : 'journey:validation:failed'
              "
            />
            <label for="validationCheck"></label>
          </div>
        </div>

        <div class="form-group row">
          <!--<div class="col-sm-2"></div>-->
          <div class="col-sm-12">
            Um die Validierung zu überschreiben, verfassen Sie bitte einen
            kurzen Kommentar zu ihrer Entscheidung.
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-12">Kommentar:</label>
          <div class="col-sm-2"></div>
          <textarea
            class="form-control col-sm-9"
            type="text"
            name="journey.validationReport.result.userMessage"
            [(ngModel)]="journey.validationReport.result.userMessage"
            #userMessageModel="ngModel"
            required
            minlength="1"
            [disabled]="journey.validationReport.result.major.endsWith('ok')"
            (change)="update()"
          ></textarea>
        </div>
      </form>
    </div>

    <div class="col-sm-12 backgroundLine"></div>

    <div class="col-sm-12 spacing-top">
      Fehlerhafte Einträge:
      <app-error-view
        [validationReport]="journey.validationReport"
      ></app-error-view>
    </div>
  </div>
</div>
