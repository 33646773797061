<div class="container spacing-top">
  <div class="row">
    <div class="col-sm-12">
      <li
        [class.highlight]="hightlightStatusNewTaxi"
        class="list-group-item new"
        (click)="newTaxi()"
      >
        Neues Taxi
      </li>
    </div>
  </div>
  <div class="row" *ngIf="taxis && taxis.length > 0">
    <div class="col-sm-12">
      Aktive Taxis
      <ul class="list-group">
        <li
          [class.highlight]="hightlightStatus[i]"
          *ngFor="let taxi of taxis; let i = index"
          class="list-group-item"
          (click)="showTaxi(taxi, i)"
        >
          {{ taxi.licensePlate }}
        </li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="pendingTaxis && pendingTaxis.length > 0">
    <div class="col-sm-12">
      Inaktive Taxis
      <ul class="list-group">
        <li
          [class.highlight]="hightlightPendingStatus[i]"
          *ngFor="let taxi of pendingTaxis; let i = index"
          class="list-group-item"
          (click)="showPending(taxi, i)"
        >
          {{ taxi.licensePlate }}
        </li>
      </ul>
    </div>
  </div>
</div>
