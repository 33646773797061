export function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}

export function isNotUndefined<T>(value?: T): value is T {
  return value !== undefined;
}

export function isNotNullOrUndefined<T>(
  value: T | null | undefined
): value is T {
  return value !== null && value !== undefined;
}

export function asCorrectedPrice(value: number) {
  return Math.round(value * 100) / 100;
}
