<app-search-user
  *ngIf="step == 1 && supportCompanyOwner"
  (onSelected)="selectUser($event)"
  (onUnSelected)="unselect()"
  [withoutCompany]="true"
  [tenantId]="tenantId"
></app-search-user>

<div
  class="info_text"
  *ngIf="(!usedUser || !usedUser?.id) && supportCompanyOwner"
>
  Sie können das Unternehmen optional einem Nutzer zuweisen.
</div>

<div *ngIf="step == 2 || newCompany">
  <form #addNewForm="ngForm">
    <div
      class="form-group row"
      *ngIf="supportCompanyOwner && usedUser && usedUser?.id != null"
    >
      <label for="inputOwner" class="col-sm-3 col-form-label">Besitzer</label>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control"
          id="inputOwner"
          value="{{ usedUser?.firstName }} {{ usedUser?.lastName }}"
          disabled
        />
        <input
          type="text"
          class="form-control"
          value="{{ usedUser?.email }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputName" class="col-sm-3 col-form-label">Unternehmen</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputName"
          name="companyName"
          [(ngModel)]="companyToCreate.companyName"
          #companyName="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputStreet" class="col-sm-3 col-form-label">Straße</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputStreet"
          name="street"
          [(ngModel)]="companyToCreate.street"
          #street="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputZipcode" class="col-sm-3 col-form-label">PLZ</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          maxlength="5"
          type="text"
          class="form-control"
          id="inputZipcode"
          name="zipcode"
          [(ngModel)]="companyToCreate.zipcode"
          #zipcode="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputCity" class="col-sm-3 col-form-label">Stadt</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputCity"
          name="city"
          [(ngModel)]="companyToCreate.city"
          #city="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPhone" class="col-sm-3 col-form-label"
        >Telefonnummer</label
      >
      <div class="col-sm-9">
        <input
          minlength="1"
          type="tel"
          class="form-control"
          id="inputPhone"
          name="phoneNumber"
          [(ngModel)]="companyToCreate.phoneNumber"
          #phoneNumber="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail" class="col-sm-3 col-form-label">E-Mail</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="email"
          class="form-control"
          id="inputEmail"
          name="email"
          [(ngModel)]="companyToCreate.email"
          #email="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputIBAN" class="col-sm-3 col-form-label">IBAN</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputIBAN"
          name="iban"
          [(ngModel)]="companyToCreate.iban"
          #iban="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputBIC" class="col-sm-3 col-form-label">BIC</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputBIC"
          name="bic"
          [(ngModel)]="companyToCreate.bic"
          #bic="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputTaxID" class="col-sm-3 col-form-label"
        >Steuernummer</label
      >
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputTaxId"
          name="taxidNumber"
          [(ngModel)]="companyToCreate.taxidNumber"
          #taxidNumber="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputRegCourt" class="col-sm-3 col-form-label"
        >Registergericht</label
      >
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputRegCourt"
          name="registerCourt"
          [(ngModel)]="companyToCreate.registerCourt"
          #registerCourt="ngModel"
          appEmptyToNullValue
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputRegNum" class="col-sm-3 col-form-label"
        >Registernummer</label
      >
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputRegNum"
          name="registerNumber"
          [(ngModel)]="companyToCreate.registerNumber"
          #registerNumber="ngModel"
          appEmptyToNullValue
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-9" [class.error]="error_msg && addNewForm.invalid">
        <span *ngIf="addNewForm.invalid || error_msg">{{ error_msg }}</span>
      </div>
      <div class="col-sm-3">
        <button
          type="button"
          class="btn btn-info"
          (click)="createCompany(addNewForm)"
        >
          Anlegen
        </button>
      </div>
    </div>
  </form>
</div>
