<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <select
        class="form-control"
        id="reports"
        [(selectedIndex)]="selectedReportIdx"
        #r
        (change)="changeReport(r.selectedIndex)"
        [disabled]="!dataLoaded || feedbackMsg"
      >
        <option *ngFor="let i of availableReports"
          >{{ i.month | monthName }} {{ i.year }}</option
        >
      </select>
      <div class="spacing-top" *ngIf="feedbackMsg">
        {{ feedbackMsg }}
      </div>
    </div>
    <div class="col-sm-6" *ngIf="dataLoaded">
      <div>
        Sie können sich alle Abrechnungen für den gewählten Monat als Archiv
        herunterladen.
      </div>
      <button
        type="button"
        class="btn btn-info"
        (click)="downloadArchive(selectedReportIdx)"
        [disabled]="feedbackMsg"
      >
        Archiv herunterladen
      </button>
    </div>
  </div>
</div>
