<div class="price" *ngIf="!isEdit">
  <span *ngIf="price">
    <span class="oldprice" *ngIf="price != priceOriginal">
      <span class="price warning" *ngIf="!priceOriginal">{{
        missingLabel
      }}</span>
      <span class="price warning" *ngIf="priceOriginal"
        >{{ priceOriginal | number: '1.2' }} €</span
      >
    </span>
    {{ price | number: '1.2' }} €
  </span>
  <span *ngIf="!price">
    <span class="price warning" *ngIf="!priceOriginal">{{ missingLabel }}</span>
    <span class="price warning" *ngIf="priceOriginal"
      >{{ priceOriginal | number: '1.2' }} €</span
    >
  </span>
  <span class="editpen" *ngIf="editable" (click)="edit()">
    <img
      width="16px"
      height="16px"
      src="../../../../../assets/report/edit.png"
    />
  </span>
</div>
<div class="price" [hidden]="!isEdit">
  <span *ngIf="price">
    <span class="oldprice">
      <span class="price warning" *ngIf="!priceOriginal">{{
        missingLabel
      }}</span>
      <span class="price warning" *ngIf="priceOriginal"
        >{{ priceOriginal | number: '1.2' }} €</span
      >
    </span>
    {{ price | number: '1.2' }} €
  </span>
  <span *ngIf="!price">
    <span class="price warning" *ngIf="!priceOriginal">{{ missingLabel }}</span>
    <span class="price warning" *ngIf="priceOriginal"
      >{{ priceOriginal | number: '1.2' }} €</span
    >
  </span>
  <form (submit)="finish($event)">
    <div class="form-group row">
      <label for="newPrice" class="col-sm-12 col-form-label"
        >{{ newLabel }}:</label
      >
      <div class="col-sm-12">
        <input
          name="newPrice"
          [(ngModel)]="effectivePrice"
          type="text"
          class="form-control"
          id="newPrice"
          type="number"
          step="0.10"
          min="0.00"
          max="999.99"
          (change)="update($event)"
        />
      </div>
    </div>
  </form>
</div>
