import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Availability,
  ClerkService,
  IncidentEntry,
  JourneyReportResponse,
  MajorEnum,
  MinorEnum,
  ReportService,
  ValidationResult,
  WaitingJourneyReportResponse,
} from '@ecsec/ff-journey-api-ang9';
import { TenantSummary } from '@ecsec/ff-tenant-api-ang9';
import * as FileSaver from 'file-saver';
import { timeout } from 'q';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { ChosenTenantSummary, TenantService } from '../../../tenant.service';
import { isNotUndefined } from '../../../util/inspection';

export type HasError<T> = T & {
  showErr?: boolean;
};

@Component({
  selector: 'app-admin-journies',
  templateUrl: './admin-journies.component.html',
  styleUrls: ['./admin-journies.component.css'],
})
export class AdminJourniesComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  tenantSummary$: Observable<ChosenTenantSummary>;
  tenantId!: string;
  constructor(
    private journeyService: ClerkService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private tenantService: TenantService
  ) {
    this.routeSub = this.route.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((params) => {
        if (params.hasOwnProperty('idx')) {
          let id = params['idx'];
          if (this.availableJourneys && this.availableJourneys.length > 0) {
            this.changeJourney(id);
          }
        }
      });
    this.tenantSummary$ = this.tenantService.chosenTenantSummary.pipe(
      filter(isNotUndefined),
      takeUntil(this.unsubscribe)
    );
    this.tenantSummary$.forEach((tenant) => {
      this.tenantId = tenant.id;
    });
  }
  private unsubscribe = new Subject();

  private routeSub: Subscription | undefined;
  private anchor: string | null = null;

  selectedJourney: JourneyReportResponse | null = null;

  journeysUnprocessed: Array<JourneyReportResponse> = [];

  journeysProcessed: Array<JourneyReportResponse> = [];

  journeysWaiting: Array<HasError<WaitingJourneyReportResponse>> = [];

  errorsInWaitingJourneys = false;

  canUpdate = false;
  updated = false;
  dataLoaded = false;
  canCreateArchive = false;
  feedbackMsg: string | null = null;
  showProcessed = false;

  availableJourneys: Array<Availability> | undefined;
  selectedJourneyMonthIdx: number | undefined;

  ngOnInit() {
    this.tenantSummary$
      .pipe(
        map((t) => t.id),
        distinctUntilChanged(),
        switchMap((tenantId) => {
          return this.journeyService
            .listAvailableJourneysForTenant(tenantId)
            .toPromise();
        }),
        catchError((evt) => {
          console.log(evt);
          return EMPTY;
        }),
        takeUntil(this.unsubscribe)
      )
      .forEach((evt) => {
        console.log(evt);
        this.availableJourneys = evt;
        this.availableJourneys.sort((a, b) => {
          const yearComparision = a.year - b.year;
          if (yearComparision !== 0) {
            return yearComparision;
          }
          return a.month - b.month;
        });
        if (this.availableJourneys.length > 0) {
          this.changeJourney(0);
        }
      });
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    this.unsubscribe.next();
  }

  loadJournies(month: number, year: number) {
    this.journeyService
      .getJourneyReportsForPeriodAndTenant(this.tenantId, month, year)
      .toPromise()
      .then((evt) => {
        this.dataLoaded = true;
        this.journeysUnprocessed = this.filterReport(evt.unprocessed || []); // , undefined, null);
        this.journeysProcessed = this.filterReport(evt.processed || []); // , undefined, null);
        this.journeysWaiting = evt.waiting || [];

        // let jw: WaitingJourneyReport = {
        //   id: '73B474734B83A85FAA3AAC609C087FB5E8A894AAFE345E2DF107C534D8D8ABD3',
        //   processingError: 'Error'
        // };
        // let jw2: WaitingJourneyReport = {
        //   id: '73B474734B83A85FAA3AAC609C087FB5E8A894AAFE345E2DF107C534D8D8ABD3',
        // };
        // let jw3: WaitingJourneyReport = {
        //   id: '83B474734B83A85FAA3AAC609C087FB5E8A894AAFE345E2DF107C534D8D8ABD3',
        //   processingError: 'Error'
        // };
        // let jw4: WaitingJourneyReport = {
        //   id: '93B474734B83A85FAA3AAC609C087FB5E8A894AAFE345E2DF107C534D8D8ABD3',
        //   processingError: 'Error'
        // };
        // this.journeysWaiting = [jw4, jw3, jw, jw2];

        this.errorsInWaitingJourneys = this.hasWaitingErrors(
          this.journeysWaiting
        );

        if (
          this.journeysUnprocessed instanceof Array &&
          this.journeysUnprocessed.length > 0
        ) {
          this.feedbackMsg = null;
          // this.addTestData();
        } else {
          this.dataLoaded = false;
          this.journeyService
            .canCreateReportForPeriodAndTenant(this.tenantId, month, year)
            .toPromise()
            .then((evt_report) => {
              console.log(evt_report);
              this.dataLoaded = true;
              this.feedbackMsg = null;
              if (evt_report) {
                this.canCreateArchive = true;
              } else {
                this.canCreateArchive = false;
              }
            })
            .catch((evt_report) => {
              console.log(evt_report);
            });
        }
        this.reorder();
      })
      .catch((evt) => console.log(evt));
  }

  addTestData() {
    let ie: IncidentEntry = {
      time: new Date(),
      leavePoint: {
        accuracy: 100,
        latitude: 50.2,
        longitude: 11.2,
      },
      reenterPoint: {
        accuracy: 100,
        latitude: 50.05,
        longitude: 11.1,
      },
    };
    const validationReport = this.journeysUnprocessed[0]?.validationReport;
    if (validationReport) {
      validationReport.borderIncidentReport?.incidentEntries?.push(ie);
      ie = {
        time: new Date(),
        leavePoint: {
          accuracy: 100,
          latitude: 50.15,
          longitude: 11.1,
        },
        reenterPoint: {
          accuracy: 100,
          latitude: 50.06,
          longitude: 11.0,
        },
      };
      validationReport.borderIncidentReport?.incidentEntries?.push(ie);
      ie = {
        time: new Date(),
        leavePoint: {
          accuracy: 100,
          latitude: 50.1512,
          longitude: 11.112,
        },
        reenterPoint: {
          accuracy: 100,
          latitude: 50.0612,
          longitude: 11.012,
        },
      };
      validationReport.borderIncidentReport?.incidentEntries?.push(ie);
      if (validationReport.userStartReport) {
        validationReport.userStartReport.location = {
          latitude: 50.12,
          longitude: 11.05,
          accuracy: 100,
        };
      }
      if (validationReport.userStopReport) {
        validationReport.userStopReport.location = {
          latitude: 50.05,
          longitude: 11.05,
          accuracy: 100,
        };
      }
      if (validationReport.taxiStartReport) {
        validationReport.taxiStartReport.location = {
          latitude: 50.1212,
          longitude: 11.0512,
          accuracy: 100,
        };
      }
      if (validationReport.taxiStopReport) {
        validationReport.taxiStopReport.location = {
          latitude: 50.0512,
          longitude: 11.0512,
          accuracy: 100,
        };
      }
    }
  }

  changeJourney(idx: number) {
    console.log(idx);
    if (!this.availableJourneys) {
      this.feedbackMsg = 'Keine Daten vorhanden...';
      return;
    }
    const journey = this.availableJourneys[idx];
    if (!journey) {
      this.feedbackMsg = 'Keine Daten vorhanden...';
      return;
    }
    this.feedbackMsg = 'Bitte warten, die Daten werden geladen ...';
    this.dataLoaded = false;
    this.selectedJourneyMonthIdx = idx;

    this.journeysUnprocessed = [];
    this.journeysProcessed = [];
    this.selectedJourney = null;
    this.canCreateArchive = false;
    this.router.navigate(['/journeys/' + idx], { replaceUrl: false });
    this.loadJournies(journey.month, journey.year);
  }

  show(journey: JourneyReportResponse, anchor: string) {
    this.selectedJourney = journey;
    this.anchor = anchor;
    window.scrollTo(0, 0);
  }

  back() {
    this.selectedJourney = null;
    this.reorder();
  }

  reorder() {
    this.reCalcMajor();
    console.log('==> reorder!!!');
    this.journeysUnprocessed.sort(this.sortJourneys);
    this.journeysProcessed.sort(this.sortJourneys);
  }

  reCalcMajor() {
    this.canUpdate = true;
    for (let i in this.journeysUnprocessed) {
      const validationReport = this.journeysUnprocessed[i].validationReport;
      if (
        validationReport &&
        validationReport.result.major === MajorEnum.Failed
      ) {
        this.canUpdate = false;
      }
    }
  }

  update() {
    console.log('UPDATE');
    this.updateJourneys(this.journeysUnprocessed);
  }

  updateSingleJourney() {
    console.log('UPDATE_SINGLE_JOURNEY');
    if (!this.selectedJourney) {
      return;
    }
    const arr = [this.selectedJourney];

    this.selectedJourney = null;

    this.updateJourneys(arr);
    this.reorder();
  }

  notifyOperator() {
    console.error('NOT IMPLEMENTED YET!');
  }

  updateJourneys(journeyArr: Array<JourneyReportResponse>) {
    journeyArr = this.changeFailedToNotAccepted(journeyArr);

    this.journeyService
      .updateJourneyReportsForTenantExternal(
        this.tenantId,
        this.mapData(journeyArr)
      )
      .toPromise()
      .then((evt: any) => {
        console.log(evt);
        if (this.selectedJourneyMonthIdx !== undefined) {
          this.changeJourney(this.selectedJourneyMonthIdx);
        }
      })
      .catch((evt: any) => {
        console.log(evt);
        if (this.selectedJourneyMonthIdx !== undefined) {
          this.changeJourney(this.selectedJourneyMonthIdx);
        }
      });
  }

  generatePDFReport() {
    console.log('generatePDFReport');
    if (!this.availableJourneys || this.selectedJourneyMonthIdx === undefined) {
      return;
    }
    const journey = this.availableJourneys[this.selectedJourneyMonthIdx];
    if (!journey) {
      return;
    }
    this.canUpdate = false;
    this.feedbackMsg = 'Archiv wird erzeugt ...';
    this.dataLoaded = false;
    this.journeyService
      .generateReportsForPeriodAndTenant(
        this.tenantId,
        journey.month,
        journey.year
      )
      .toPromise()
      .then((evt) => {
        console.log(evt);
        this.feedbackMsg =
          'Der Report wurde erfolgreich erstellt. Die Seite wird in kürze neu geladen, bitte warten.';
        setTimeout(() => {
          this.ngOnInit();
        }, 5000);
      })
      .catch((evt: any) => {
        console.log(evt);
        this.feedbackMsg = 'Bei der Erstellung ist ein Fehler aufgetreten.';
        this.canUpdate = true;
      });
  }

  ngAfterViewChecked(): void {
    if (this.anchor !== null || this.anchor !== '') {
      let anchor = '#' + this.anchor;
      let elem = document.querySelector(anchor);
      if (elem !== null) {
        elem.scrollIntoView();
        this.anchor = null;
      }
    }
  }

  private hasWaitingErrors(
    toTest: Array<WaitingJourneyReportResponse>
  ): boolean {
    for (const report of toTest) {
      if (report.processing_error) {
        return true;
      }
    }
    return false;
  }

  private sortJourneys(a: JourneyReportResponse, b: JourneyReportResponse) {
    let compareResult = 0;

    if (a.validationReport.result.major === b.validationReport.result.major) {
      compareResult = 0;
    } else if (a.validationReport.result.major === MajorEnum.Failed) {
      compareResult = -1;
    } else if (b.validationReport.result.major === MajorEnum.Failed) {
      compareResult = 1;
    }
    if (compareResult === 0) {
      let dateA = new Date(a.startTime || 0);
      let dateB = new Date(b.startTime || 0);

      return dateA.getTime() - dateB.getTime();
    } else {
      return compareResult;
    }
  }

  private changeFailedToNotAccepted(
    journeyArr: Array<JourneyReportResponse>
  ): Array<JourneyReportResponse> {
    for (const entry of journeyArr) {
      const validationReport = entry.validationReport;
      const result = validationReport.result;
      if (result && result.major === MajorEnum.Failed) {
        result.major = MajorEnum.NotAccepted;
      }
    }
    return journeyArr;
  }

  private filterReport(
    toSlice: Array<JourneyReportResponse>,
    notMajor: MajorEnum = MajorEnum.Failed,
    notMinor: MinorEnum = MinorEnum.OnlyUserstartAvailable
  ): Array<JourneyReportResponse> {
    let copy: Array<JourneyReportResponse> = JSON.parse(
      JSON.stringify(toSlice)
    );
    let work: Array<JourneyReportResponse> = [];

    console.groupCollapsed('Validation - filter:', notMajor, notMinor);
    for (const entry of copy) {
      const validationreport = entry.validationReport;
      if (!validationreport || !validationreport.result) {
        continue;
      }
      const currentResult = validationreport.result;
      console.log(
        currentResult.major,
        currentResult.minor,
        currentResult.major === notMajor,
        currentResult.minor === notMinor,
        currentResult.major === notMajor && currentResult.minor === notMinor
      );
      if (
        currentResult.major === notMajor &&
        currentResult.minor === notMinor
      ) {
        continue;
      } else {
        work.push(entry);
      }
    }
    console.groupEnd();

    return work;
  }

  // TODO: remove for new api
  private mapData(
    toMap: Array<JourneyReportResponse>
  ): Array<JourneyReportResponse> {
    let work = JSON.parse(JSON.stringify(toMap));
    return work;
  }
}

@Pipe({ name: 'monthName' })
export class MonthPipe implements PipeTransform {
  months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];
  transform(value: number): string {
    return this.months[value - 1];
  }
}
