<div class="price" *ngIf="!isEdit">
  <span *ngIf="journey.subsidisedPaid">
    <span
      class="oldprice"
      *ngIf="journey.subsidisedPaid != journey.subsidisedPaidOriginal"
    >
      <span class="price warning" *ngIf="!journey.subsidisedPaidOriginal"
        >Keine Subvention vorhanden</span
      >
      <span class="price warning" *ngIf="journey.subsidisedPaidOriginal"
        >{{ journey.subsidisedPaidOriginal | number: '1.2' }} €</span
      >
    </span>
    {{ journey.subsidisedPaid | number: '1.2' }} €
  </span>
  <span *ngIf="!journey.subsidisedPaid">
    <span class="price warning" *ngIf="!journey.subsidisedPaidOriginal"
      >Keine Subvention vorhanden</span
    >
    <span class="price warning" *ngIf="journey.subsidisedPaidOriginal"
      >{{ journey.subsidisedPaidOriginal | number: '1.2' }} €</span
    >
  </span>
  <span class="editpen" *ngIf="editable" (click)="edit()">
    <img
      width="16px"
      height="16px"
      src="../../../../../assets/report/edit.png"
    />
  </span>
</div>
<div class="price" [hidden]="!isEdit">
  <span *ngIf="journey.subsidisedPaid">
    <span class="oldprice">
      <span class="price warning" *ngIf="!journey.subsidisedPaidOriginal"
        >Keine Subvention vorhanden</span
      >
      <span class="price warning" *ngIf="journey.subsidisedPaidOriginal"
        >{{ journey.subsidisedPaidOriginal | number: '1.2' }} €</span
      >
    </span>
    {{ journey.subsidisedPaid | number: '1.2' }} €
  </span>
  <span *ngIf="!journey.subsidisedPaid">
    <span class="price warning" *ngIf="!journey.subsidisedPaidOriginal"
      >Keine Subvention vorhanden</span
    >
    <span class="price warning" *ngIf="journey.subsidisedPaidOriginal"
      >{{ journey.subsidisedPaidOriginal | number: '1.2' }} €</span
    >
  </span>
  <form (submit)="finish($event)">
    <div class="form-group row">
      <label for="newSubsidy" class="col-sm-12 col-form-label"
        >Neue Subvention:</label
      >
      <div class="col-sm-12">
        <input
          name="subsidyNewSubsidy"
          [(ngModel)]="paid"
          type="text"
          class="form-control"
          id="newSubsidy"
          type="number"
          step="0.01"
          min="0.01"
          max="999.99"
          (change)="update($event)"
        />
      </div>
    </div>
  </form>
</div>
