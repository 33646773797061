<div class="error">
  <ul>
    <li
      *ngIf="
        validationReport &&
        validationReport.userStartReport?.result.major.endsWith('failed')
      "
    >
      Fahrgast Start
      <ul>
        <app-error-view-entry
          [result]="validationReport.userStartReport?.certificateCheck"
          [infoText]="'Zertifikatsprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.userStartReport?.signatureCheck"
          [infoText]="'Signaturprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.userStartReport?.positionConstraintCheck"
          [infoText]="'Regionsprüfung'"
        ></app-error-view-entry>
      </ul>
    </li>

    <li
      *ngIf="
        validationReport &&
        validationReport.taxiStartReport?.result.major.endsWith('failed')
      "
    >
      Taxi Start
      <ul>
        <app-error-view-entry
          [result]="validationReport.taxiStartReport?.certificateCheck"
          [infoText]="'Zertifikatsprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.taxiStartReport?.signatureCheck"
          [infoText]="'Signaturprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.taxiStartReport?.positionConstraintCheck"
          [infoText]="'Regionsprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.taxiStartReport?.positionComparisonCheck"
          [infoText]="'Positionsabweichung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.taxiStartReport?.timeComparisonCheck"
          [infoText]="'Zeitabweichung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.taxiStartReport?.sigReferenceCheck"
          [infoText]="'Nachrichtenreferenzprüfung'"
        ></app-error-view-entry>
      </ul>
    </li>

    <li
      *ngIf="
        validationReport &&
        validationReport.userStopReport?.result.major.endsWith('failed')
      "
    >
      Fahrgast Stopp
      <ul>
        <app-error-view-entry
          [result]="validationReport.userStopReport?.signatureCheck"
          [infoText]="'Signaturprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.userStopReport?.positionConstraintCheck"
          [infoText]="'Regionsprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.userStopReport?.positionComparisonCheck"
          [infoText]="'Positionsabweichung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.userStopReport?.timeComparisonCheck"
          [infoText]="'Zeitabweichung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.userStopReport?.timePlausabilityCheck"
          [infoText]="'Fahrtzeitprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.userStopReport?.sigReferenceCheck"
          [infoText]="'Nachrichtenreferenzprüfung'"
        ></app-error-view-entry>
      </ul>
    </li>

    <li
      *ngIf="
        validationReport &&
        validationReport.taxiStopReport?.result.major.endsWith('failed')
      "
    >
      Taxi Stopp
      <ul>
        <app-error-view-entry
          [result]="validationReport.taxiStopReport?.signatureCheck"
          [infoText]="'Signaturprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.taxiStopReport?.positionConstraintCheck"
          [infoText]="'Regionsprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.taxiStopReport?.pricePlausabilityCheck"
          [infoText]="'Preisüberprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.taxiStopReport?.sigReferenceCheck"
          [infoText]="'Nachrichtenreferenzprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.taxiStopReport?.timePlausabilityCheck"
          [infoText]="'Fahrtzeitprüfung'"
        ></app-error-view-entry>
      </ul>
    </li>
    <li
      *ngIf="
        validationReport &&
        validationReport.borderIncidentReport?.result.major.endsWith('failed')
      "
    >
      Landkreisprüfung
      <ul>
        <app-error-view-entry
          [result]="validationReport.borderIncidentReport?.signatureCheck"
          [infoText]="'Signaturprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="validationReport.borderIncidentReport?.sigReferenceCheck"
          [infoText]="'Nachrichtenreferenzprüfung'"
        ></app-error-view-entry>
        <app-error-view-entry
          [result]="
            validationReport.borderIncidentReport?.timePlausabilityCheck
          "
          [infoText]="'Fahrtzeitprüfung'"
        ></app-error-view-entry>
      </ul>
    </li>
  </ul>
  <!--<button (click)="toggleElement()">toggle</button>
  <div class="elemnt" *ngIf="toggle" [@flyInOut]>
    element
  </div>-->
</div>
