import { Component } from '@angular/core';

@Component({
  selector: 'app-messagebox',
  template: `
    <div
      (click)="onContainerClicked($event)"
      class="modal fade"
      tabindex="-1"
      [ngClass]="{ show: visibleAnimate }"
      [ngStyle]="{
        display: visible ? 'block' : 'none',
        opacity: visibleAnimate ? 1 : 0
      }"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <ng-content select=".app-messagebox-header"></ng-content>
          </div>
          <div class="modal-body">
            <ng-content select=".app-messagebox-body"></ng-content>
          </div>
          <div class="modal-footer">
            <ng-content select=".app-messagebox-footer"></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class MessageboxComponent {
  public visible = false;
  public visibleAnimate = false;

  public show(): void {
    this.visible = true;
    setTimeout(() => (this.visibleAnimate = true), 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => (this.visible = false), 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((event.target as HTMLElement).classList.contains('modal')) {
      this.hide();
    }
  }
}
