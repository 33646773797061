import { Directive, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[appEmptyToNullValue]',
})
export class EmptyToNullDirective {
  constructor(@Self() private ngControl: NgControl) {}

  @HostListener('input', ['$event.target'])
  onEvent(target: HTMLInputElement) {
    if (this.ngControl.value?.trim() === '') {
      this.ngControl.reset(null);
    }
  }
}
