import { Injectable, OnDestroy } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { CLERK, COMPANY, PASSENGER, SALESPERSON, Scope } from './auth.service';

function getValueOrDefault<K, V>(map: Map<K, V>, key: K, creator: () => V): V {
  let found = map.get(key);
  if (found === undefined) {
    found = creator();
    map.set(key, found);
  }
  return found;
}

export interface UserSummary {
  isAuthenticated: boolean;
}

export type TenantSpecificScope = CLERK | COMPANY | PASSENGER;
export type GenericScope = SALESPERSON;
export type RelevantScope = CLERK | COMPANY | SALESPERSON;

export type AllScopes = GenericScope | TenantSpecificScope;

export interface IdentifiedEvaluation {
  identity: 'authenticated';
}

export interface ScopeEvaluation extends IdentifiedEvaluation {
  overview: Set<AllScopes>;
  assignmentsByTenant: Map<string, Set<TenantSpecificScope>>;
  assignmentsByScope: Map<TenantSpecificScope, Set<string>>;
}

export interface AnonymousIdentityEvaluation {
  identity: 'anonymous';
}

export const relevantScopes: Set<RelevantScope> = new Set<RelevantScope>([
  CLERK,
  SALESPERSON,
  COMPANY,
]);

const allTenantSpecificScopes: Set<TenantSpecificScope> = new Set<
  TenantSpecificScope
>([CLERK, PASSENGER, COMPANY]);

const allScopePrefixes: Map<string, TenantSpecificScope> = new Map();
for (const currentScope of allTenantSpecificScopes) {
  allScopePrefixes.set(`${currentScope}:`, currentScope);
}

const AnonymousUser: AnonymousIdentityEvaluation = {
  identity: 'anonymous',
};

@Injectable({ providedIn: 'root' })
export class AuthenticatedUserService implements OnDestroy {
  private scopes$ = new BehaviorSubject<
    ScopeEvaluation | AnonymousIdentityEvaluation
  >(AnonymousUser);

  private subscription: Subscription;

  constructor(private oauthService: OAuthService) {
    this.subscription = this.oauthService.events.subscribe({
      next: (evt) => {
        console.log('Event received:', evt);
        if (evt.type === 'token_received') {
          const rawArray: Array<string> =
            (this.oauthService.getGrantedScopes() as Array<string>) ?? [];

          console.log('Granted scopes: ', rawArray);
          const resultOverview: Set<Scope> = new Set();
          const assignmentsByTenant: Map<
            string,
            Set<TenantSpecificScope>
          > = new Map();
          const assignmentsByScope: Map<
            TenantSpecificScope,
            Set<string>
          > = new Map();
          for (const rawScope of rawArray) {
            console.log('Evaluating: ', rawScope);
            const mapped: Scope | undefined = Scope.find((s) => s === rawScope);
            if (mapped) {
              resultOverview.add(mapped);
            } else {
              for (const [currentPrefix, currentScope] of allScopePrefixes) {
                if (rawScope.startsWith(currentPrefix)) {
                  const tenantId = rawScope.substring(currentPrefix.length);
                  const tenantAssignments = getValueOrDefault(
                    assignmentsByTenant,
                    tenantId,
                    () => new Set()
                  );
                  tenantAssignments.add(currentScope);

                  const scopeAssignments = getValueOrDefault(
                    assignmentsByScope,
                    currentScope,
                    () => new Set()
                  );
                  scopeAssignments.add(tenantId);
                }
              }
            }
          }
          const result: ScopeEvaluation = {
            identity: 'authenticated',
            overview: resultOverview,
            assignmentsByScope,
            assignmentsByTenant,
          };
          console.log('Authenticated scopes:', result);
          this.scopes$.next(result);
        } else if (evt.type === 'logout') {
          this.scopes$.next(AnonymousUser);
        }
      },
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  hasScope(str: string) {
    let sarr: Array<string> = this.oauthService.getGrantedScopes() as Array<
      string
    >;
    if (!sarr) {
      return false;
    }

    for (let s in sarr) {
      if (sarr[s] === str) {
        return true;
      }
    }

    return false;
  }

  hasAnyScope(scopes: Array<string>) {
    let sarr: Array<string> = this.oauthService.getGrantedScopes() as Array<
      string
    >;

    if (!sarr) {
      return false;
    }

    for (const scope of scopes) {
      if (sarr.includes(scope)) {
        return true;
      }
    }
    return false;
  }
  isLoggedIn() {
    return this.oauthService.hasValidAccessToken();
  }

  get user() {
    return this.scopes$.asObservable();
  }
}
