import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { AuthenticatedUserService, relevantScopes } from '../user.service';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css'],
})
export class LandingpageComponent implements OnInit {
  constructor(private authService: AuthenticatedUserService) {}

  ngOnInit() {}

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  hasScope(str: string) {
    return this.authService.hasScope(str);
  }

  isAuthenticated() {
    return this.authService.user.pipe(
      map((user) => user.identity === 'authenticated')
    );
  }

  hasRelevantScope() {
    return this.authService.user.pipe(
      map((user) => {
        if (user.identity === 'authenticated') {
          for (const currentScope of relevantScopes) {
            if (user.overview.has(currentScope)) {
              return true;
            }
          }
        }
        return false;
      })
    );
  }
}
