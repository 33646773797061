<div class="container">
  <div class="row">
    <div
      class="col-sm-12"
      [style.display]="
        !(hasRelevantScope() | async) && (isAuthenticated() | async)
          ? 'initial'
          : 'none'
      "
    >
      Ihre Daten wurden erfolgreich an das FiftyFifty Control Center
      übermittelt. Sie haben aktuell noch keine Berechtigung um das Control
      Center zu nutzen. Bitte wenden Sie sich an den zuständigen Mitarbeiter
      ihres Landratsamtes, damit dieser Ihren Zugang freischaltet.
    </div>
    <div
      class="col-sm-12"
      [style.display]="(hasRelevantScope() | async) ? 'initial' : 'none'"
    >
      Herzlich willkommen im FiftyFifty Control Center.
    </div>
    <div
      class="col-sm-12"
      [style.display]="!(isAuthenticated() | async) ? 'initial' : 'none'"
    >
      Bitte loggen Sie sich ein um das FiftyFifty Control Center zu nutzen.
    </div>
  </div>
</div>
