import { Component, Input, OnInit } from '@angular/core';
import { JourneyValidationReport } from '@ecsec/ff-journey-api-ang9';

@Component({
  selector: 'app-error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.css'],
})
export class ErrorViewComponent implements OnInit {
  toggle = false;

  @Input() validationReport!: JourneyValidationReport;

  constructor() {}

  ngOnInit() {}

  toggleElement() {
    this.toggle = !this.toggle;
  }
}
