<div *ngIf="taxi">
  <h2>{{ taxi.licensePlate }}</h2>
  <div><span>Serial: </span>{{ taxi.serial }}</div>
  <div>
    <form>
      <div class="form-group row">
        <div class="col-sm-12">
          <button
            type="button"
            class="btn btn-danger"
            (click)="removeTaxi(taxi.serial)"
            [disabled]="!dataLoaded"
          >
            Löschen
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div *ngIf="newTaxi">
  <form #addTaxi="ngForm">
    <div class="form-group row" *ngIf="!nonce">
      <label for="inputLicensePlate" class="col-sm-2 col-form-label"
        >Nummernschild</label
      >
      <div class="col-sm-12">
        <input
          name="licensePlate"
          [(ngModel)]="licensePlate"
          type="text"
          class="form-control"
          id="inputLicensePlate"
          minlength="3"
          [placeholder]="'TAX I 5050'"
          #plate="ngModel"
        />
      </div>
    </div>
    <div class="form-group row" *ngIf="!nonce">
      <button
        type="button"
        class="btn btn-info"
        (click)="requestNonce(licensePlate)"
        [disabled]="addTaxi.invalid || !licensePlate"
      >
        Ok
      </button>
    </div>
    <div class="form-group row">
      <div
        class="col-sm-12"
        *ngIf="nonce"
        (click)="qrcodeDisplay.value = nonce"
      >
        <qr-code
          #qrcodeDisplay
          [value]="nonce"
          [size]="350"
          canvas="true"
        ></qr-code>
      </div>
    </div>
    <div class="form-group row" *ngIf="nonce">
      <div class="col-sm-12">
        <button type="button" class="btn btn-info" (click)="finish()">
          Ok
        </button>
      </div>
    </div>
  </form>
</div>

<div class="form-group row" *ngIf="feedbackMsg">
  <div class="col-sm-12">
    {{ feedbackMsg }}
  </div>
</div>
