<div class="form-check form-check-inline report-icon" *ngIf="certificateCheck" (click)="select()" [class.ok]="result.major.endsWith('ok')"
  [class.error]="result.major.endsWith('failed')" title="Zertifikatsüberprüfung">
  <img class="report-icon-img" height="32px" alt="certificateCheck" [src]="'assets/report/certificateCheck.png'">
</div>

<div class="form-check form-check-inline report-icon" *ngIf="signatureCheck" (click)="select()" [class.ok]="result.major.endsWith('ok')"
  [class.error]="result.major.endsWith('failed')" title="Signaturüberprüfung">
  <img class="report-icon-img" height="32px" alt="signatureCheck" [src]="'assets/report/signatureCheck.png'">
</div>

<div class="form-check form-check-inline report-icon" *ngIf="sigReferenceCheck" (click)="select()" [class.ok]="result.major.endsWith('ok')"
  [class.error]="result.major.endsWith('failed')" title="Signaturüberprüfung">
  <img class="report-icon-img" height="32px" alt="sigReferenceCheck" [src]="'assets/report/sigReferenceCheck.png'">
</div>

<div class="form-check form-check-inline report-icon" *ngIf="positionConstraintCheck" (click)="select()" [class.ok]="result.major.endsWith('ok')"
  [class.error]="result.major.endsWith('failed')" title="Positionsüberprüfung">
  <img class="report-icon-img" height="32px" alt="positionConstraintCheck" [src]="'assets/report/positionConstraintCheck.png'">
</div>

<div class="form-check form-check-inline report-icon" *ngIf="positionComparisonCheck" (click)="select()" [class.ok]="result.major.endsWith('ok')"
  [class.error]="result.major.endsWith('failed')" title="Positionsüberprüfung">
  <img class="report-icon-img" height="32px" alt="positionComparisonCheck" [src]="'assets/report/positionComparisonCheck.png'">
</div>

<div class="form-check form-check-inline report-icon" *ngIf="timeComparisonCheck" (click)="select()" [class.ok]="result.major.endsWith('ok')"
  [class.error]="result.major.endsWith('failed')" title="Zeitüberprüfung">
  <img class="report-icon-img" height="32px" alt="timeComparisonCheck" [src]="'assets/report/timeComparisonCheck.png'">
</div>

<div class="form-check form-check-inline report-icon" *ngIf="timePlausabilityCheck" (click)="select()" [class.ok]="result.major.endsWith('ok')"
  [class.error]="result.major.endsWith('failed')" title="Zeitüberprüfung">
  <img class="report-icon-img" height="32px" alt="timePlausabilityCheck" [src]="'assets/report/timePlausabilityCheck.png'">
</div>

<div class="form-check form-check-inline report-icon" *ngIf="pricePlausabilityCheck" (click)="select()" [class.ok]="result.major.endsWith('ok')"
  [class.error]="result.major.endsWith('failed')" title="Preisüberprüfung">
  <img class="report-icon-img" height="32px" alt="pricePlausabilityCheck" [src]="'assets/report/pricePlausabilityCheck.png'">
</div>