import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  AuthConfig,
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { QRCodeModule } from 'angular2-qrcode';

import { AppComponent } from './app.component';
import { TaxiDetailComponent } from './company/taxi/taxi-detail/taxi-detail.component';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { CompaniesComponent } from './admin/companies/companies.component';
import { CompanyDetailAddnewComponent } from './admin/companies/company-detail-addnew/company-detail-addnew.component';
import { CompanyDetailComponent } from './admin/companies/company-detail/company-detail.component';
import { AdminJourniesDetailComponent } from './admin/journeys/admin-journies-detail/admin-journies-detail.component';
import { ErrorViewEntryComponent } from './admin/journeys/admin-journies-detail/error-view/error-view-entry/error-view-entry.component';
import { ErrorViewComponent } from './admin/journeys/admin-journies-detail/error-view/error-view.component';
import { ReportCheckIconComponent } from './admin/journeys/admin-journies-detail/report-check-icon/report-check-icon.component';
import {
  AdminJourniesComponent,
  MonthPipe,
} from './admin/journeys/admin-journies/admin-journies.component';
import { JourneyOverviewComponent } from './admin/journeys/admin-journies/journey-overview/journey-overview.component';
import { SearchUserComponent } from './admin/user/search-user/search-user.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './auth.service';
import { CompanyOverviewComponent } from './company/company-overview/company-overview.component';
import { TaxiOverviewComponent } from './company/taxi/taxi-overview/taxi-overview.component';
import { LandingpageComponent } from './landingpage/landingpage.component';

import {
  ApiModule as HttpJourneyModule,
  Configuration as JourneyConfiguration,
} from '@ecsec/ff-journey-api-ang9';
import {
  ApiModule as HttpPassengerModule,
  Configuration as PassengerConfiguration,
} from '@ecsec/ff-passenger-api-ang9';
import {
  ApiModule as HttpTaxiModule,
  Configuration as TaxiConfiguration,
} from '@ecsec/ff-taxi-api-ang9';
import {
  ApiModule as HttpTenantModule,
  Configuration as TenantConfiguration,
} from '@ecsec/ff-tenant-api-ang9';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { CompanyTaxiDetailComponent } from './admin/companies/taxi/company-taxi-detail/company-taxi-detail.component';
import { CompanyTaxiOverviewComponent } from './admin/companies/taxi/company-taxi-overview/company-taxi-overview.component';
import { JourneyPriceComponent } from './admin/journeys/admin-journies/journey-price/journey-price.component';
import { ReportOverviewComponent } from './admin/reports/report-overview/report-overview.component';
import { UserSettingsComponent } from './admin/user/user-settings/user-settings.component';
import { MessageboxComponent } from './app.messagebox';
import { EmptyToNullDirective } from './forms/empty-to-null.directive';
import { AuthInterceptor } from './guards/http-interceptor';
import { LogoutpageComponent } from './logoutpage/logoutpage.component';
import { TenantSelectionComponent } from './tenant-selection/tenant-selection.component';
import { SubsidisedPriceComponentComponent } from './admin/journeys/admin-journies/subsidised-price-component/subsidised-price-component.component';
import { EditablePriceComponentComponent } from './admin/journeys/admin-journies/editable-price-component/editable-price-component.component';
import { JourneyPricingComponentComponent } from './admin/journeys/admin-journies/journey-pricing-component/journey-pricing-component.component';
import { StatisticsComponent } from './admin/reports/statistics/statistics.component';
import { CompanyPendingTaxiDetailComponent } from './admin/companies/taxi/company-pending-taxi-detail/company-pending-taxi-detail.component';

// https://stackoverflow.com/questions/37649164/how-to-add-bootstrap-to-an-angular-cli-project

export function tenantConfig() {
  return new TenantConfiguration({
    basePath: environment.tenantUrl,
  });
}

export function taxiConfig() {
  return new TaxiConfiguration({
    basePath: environment.taxiUrl,
  });
}

export function passengerConfig() {
  return new PassengerConfiguration({
    basePath: environment.passengerUrl,
  });
}

export function journeyConfig() {
  return new JourneyConfiguration({
    basePath: environment.journeyUrl,
  });
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpTenantModule.forRoot(tenantConfig),
    HttpTaxiModule.forRoot(taxiConfig),
    HttpPassengerModule.forRoot(passengerConfig),
    HttpJourneyModule.forRoot(journeyConfig),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          environment.tenantUrl,
          environment.taxiUrl,
          environment.journeyUrl,
          environment.passengerUrl,
        ],
        sendAccessToken: true,
      },
    }),
    QRCodeModule,
    LeafletModule,
    NgbModule,
  ],
  declarations: [
    AppComponent,
    TaxiDetailComponent,
    LandingpageComponent,
    CompanyOverviewComponent,
    TaxiOverviewComponent,
    CompaniesComponent,
    CompanyDetailComponent,
    CompanyDetailAddnewComponent,
    AdminJourniesComponent,
    AdminJourniesDetailComponent,
    SearchUserComponent,
    ReportCheckIconComponent,
    JourneyOverviewComponent,
    ErrorViewComponent,
    ErrorViewEntryComponent,
    UserSettingsComponent,
    LogoutpageComponent,
    ReportOverviewComponent,
    MonthPipe,
    CompanyTaxiOverviewComponent,
    CompanyTaxiDetailComponent,
    CompanyPendingTaxiDetailComponent,
    JourneyPriceComponent,
    MessageboxComponent,
    TenantSelectionComponent,
    EmptyToNullDirective,
    SubsidisedPriceComponentComponent,
    EditablePriceComponentComponent,
    JourneyPricingComponentComponent,
    StatisticsComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    HttpClientModule,
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
