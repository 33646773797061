import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { JourneyReportResponse } from '@ecsec/ff-journey-api-ang9';
import { isNotNullOrUndefined } from '../../../../util/inspection';

@Component({
  selector: 'app-journey-price',
  templateUrl: './journey-price.component.html',
  styleUrls: ['./journey-price.component.css'],
})
export class JourneyPriceComponent implements OnInit, OnDestroy {
  isEdit = false;

  @Output() priceChanged = new EventEmitter<boolean>();

  constructor() {}

  @Input()
  journey!: JourneyReportResponse;

  @Input()
  editable = false;

  ngOnInit() {}

  get paid(): number | undefined {
    return isNotNullOrUndefined(this.journey)
      ? isNotNullOrUndefined(this.journey.totalPaid)
        ? this.journey.totalPaid
        : this.journey.totalPaidOriginal
      : undefined;
  }

  set paid(value: number | undefined) {
    this.journey.totalPaid = value;
  }

  edit() {
    this.isEdit = true;
  }

  finish(event: any) {
    console.log('Price Finished!', event);
    this.isEdit = false;
  }

  update(event: any) {
    let hasChanged = false;
    console.log('Price Changed!', event);
    if (
      this.journey &&
      this.journey.totalPaid &&
      this.journey.totalPaid !== this.journey.totalPaidOriginal
    ) {
      hasChanged = true;
    }
    this.priceChanged.emit(hasChanged);
  }
  ngOnDestroy(): void {}
}
