import { Component, Input, OnInit } from '@angular/core';
import { ValidationResult } from '@ecsec/ff-journey-api-ang9';

@Component({
  selector: 'app-error-view-entry',
  templateUrl: './error-view-entry.component.html',
  styleUrls: ['./error-view-entry.component.css'],
})
export class ErrorViewEntryComponent implements OnInit {
  @Input() result!: ValidationResult;
  @Input() infoText = 'TODO';

  show = true;

  constructor() {}

  ngOnInit() {}
}
