<div *ngIf="taxi">
	<h2>{{ taxi.licensePlate }}</h2>
	<div>
		<span>Serial: </span>{{taxi.serial}}
	</div>
	<div>
		<form>
			<div class="form-group row">
				<div class="col-sm-12">
					<button type="button" class="btn btn-danger" (click)="removeTaxi(taxi.serial)">Löschen</button>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="newTaxi">
	<form>
		<div class="form-group row" *ngIf="!nonce">
			<label for="inputLicensePlate" class="col-sm-2 col-form-label">Nummernschild</label>
			<div class="col-sm-12">
				<input [ngModelOptions]="{standalone: true}" [(ngModel)]="licensePlate" type="text" class="form-control" id="inputLicensePlate">
			</div>
		</div>
		<div class="form-group row" *ngIf="!nonce">
			<div class="col-sm-12">
				<button type="button" class="btn btn-info" (click)="requestNonce(licensePlate)">Ok</button>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-sm-12" *ngIf="nonce">
				<qr-code [value]="nonce" [size]="350"></qr-code>
			</div>
		</div>
		<div class="form-group row" *ngIf="nonce">
			<div class="col-sm-12">
				<button type="button" class="btn btn-info" (click)="finish()">Ok</button>
			</div>
		</div>
	</form>
	<!--<img src="../../assets/qr_code.jpg" />-->
</div>