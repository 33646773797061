import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TaxiCompanyService, TaxiTicketInfo } from '@ecsec/ff-taxi-api-ang9';
import { TenantSummary } from '@ecsec/ff-tenant-api-ang9';
import { until } from 'protractor';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import {
  HasTenantIds,
  HasUserEvaluation,
  TenantService,
} from '../../../tenant.service';
import { isNotUndefined } from '../../../util/inspection';

@Component({
  selector: 'app-taxi-overview',
  templateUrl: './taxi-overview.component.html',
  styleUrls: ['./taxi-overview.component.css'],
})
export class TaxiOverviewComponent implements OnInit, OnDestroy {
  hightlightStatus: Array<boolean> = [];
  private unsubscribe = new Subject();
  hightlightStatusNewTaxi = false;
  selectedTaxi: TaxiTicketInfo | null = null;

  taxis: Array<TaxiTicketInfo> = [];

  private routeSub: Subscription;
  tenantSummary$: Observable<HasUserEvaluation<HasTenantIds<TenantSummary>>>;
  tenantId!: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eTicketService: TaxiCompanyService,
    private tenantService: TenantService
  ) {
    this.routeSub = this.route.params.subscribe((params) => {
      this.ngOnInit();
    });
    this.tenantSummary$ = this.tenantService.chosenTenantSummary.pipe(
      filter(isNotUndefined),
      takeUntil(this.unsubscribe)
    );
  }

  ngOnInit() {
    this.tenantSummary$
      .pipe(
        switchMap((tenant) => {
          return this.eTicketService.listTaxisByCompany(tenant.id).toPromise();
        }),
        catchError((err) => {
          console.error(err);
          return EMPTY;
        }),
        takeUntil(this.unsubscribe)
      )
      .forEach((evt) => {
        this.taxis = evt;
        let id = this.route.snapshot.paramMap.get('id');

        if (id === 'new') {
          this.newTaxi();
        } else {
          for (let index = 0; index < this.taxis.length; index++) {
            const taxi = this.taxis[index];
            if (taxi.serial === id) {
              this.show(taxi, index);
              break;
            }
          }
        }
      });
  }

  show(taxi: TaxiTicketInfo, idx: number) {
    this.hightlightStatus = [];
    this.hightlightStatusNewTaxi = false;
    this.hightlightStatus[idx] = true;
    this.selectedTaxi = taxi;
    console.log(taxi, idx);
    this.router.navigate(['../taxis', this.selectedTaxi.serial]);
  }

  newTaxi() {
    this.hightlightStatus = [];
    this.hightlightStatusNewTaxi = true;
    this.selectedTaxi = null;
    this.router.navigate(['../taxis', 'new']);
  }

  reset() {
    this.hightlightStatus = [];
    this.hightlightStatusNewTaxi = false;
    this.selectedTaxi = null;

    this.ngOnInit();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
