import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  asCorrectedPrice,
  isNotNullOrUndefined,
} from '../../../../util/inspection';

@Component({
  selector: 'app-editable-price-component',
  templateUrl: './editable-price-component.component.html',
  styleUrls: ['./editable-price-component.component.css'],
})
export class EditablePriceComponentComponent implements OnInit {
  isEdit = false;

  @Output() priceChanged = new EventEmitter<boolean>();

  constructor() {}

  @Input()
  price: number | undefined;

  @Output() priceChange = new EventEmitter<number | undefined>();

  @Input()
  priceOriginal: number | undefined;

  @Input()
  editable = false;

  @Input()
  missingLabel: string = 'Kein Preis vorhanden';

  @Input()
  newLabel: string = 'Neuer Preis';

  ngOnInit() {}

  get effectivePrice(): number | undefined {
    return isNotNullOrUndefined(this.price) ? this.price : this.priceOriginal;
  }

  set effectivePrice(value: number | undefined) {
    const convertedValue = Number(value);
    if (!isNaN(convertedValue) && typeof convertedValue === 'number') {
      const fixedValue = asCorrectedPrice(convertedValue);
      this.price = fixedValue;
      this.priceChange.next(fixedValue);
    }
  }

  edit() {
    this.isEdit = true;
  }

  finish(event: any) {
    this.isEdit = false;
  }

  update(event: any) {
    let hasChanged = false;
    if (this.price && this.price !== this.priceOriginal) {
      hasChanged = true;
    }
    this.priceChanged.emit(hasChanged);
  }
  ngOnDestroy(): void {}
}
