<app-editable-price-component
  [price]="journey.totalPaid"
  (priceChange)="onPriceChange($event)"
  [priceOriginal]="journey.totalPaidOriginal"
  [editable]="editable"
  missingLabel="Kein Preis vorhanden"
  newLabel="Neuer Preis"
>
</app-editable-price-component>

<app-editable-price-component
  [price]="journey.subsidisedPaid"
  (priceChange)="onSubsidisedPriceChange($event)"
  [priceOriginal]="journey.subsidisedPaidOriginal"
  [editable]="editable"
  missingLabel="Keine Subvention vorhanden"
  newLabel="Neue Subvention"
>
</app-editable-price-component>
