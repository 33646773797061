import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValidationResult } from '@ecsec/ff-journey-api-ang9';

@Component({
  selector: 'app-report-check-icon',
  templateUrl: './report-check-icon.component.html',
  styleUrls: ['./report-check-icon.component.css'],
})
export class ReportCheckIconComponent implements OnInit {
  @Output() onSelected = new EventEmitter<ValidationResult>();

  @Input() certificateCheck = false;
  @Input() signatureCheck = false;
  @Input() sigReferenceCheck = false;

  @Input() positionConstraintCheck = false;
  @Input() positionComparisonCheck = false;

  @Input() timeComparisonCheck = false;
  @Input() timePlausabilityCheck = false;

  @Input() pricePlausabilityCheck = false;

  @Input() result!: ValidationResult;

  constructor() {}

  ngOnInit() {}

  select() {
    this.onSelected.emit(this.result);
  }
}
