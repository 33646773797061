import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CompaniesData, TaxiCompanyService } from '@ecsec/ff-taxi-api-ang9';
import { TenantSummary } from '@ecsec/ff-tenant-api-ang9';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { HasTenantIds, HasUserEvaluation } from '../../tenant.service';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.css'],
})
export class CompanyOverviewComponent implements OnInit, OnDestroy {
  @Input() tenantSummary$!: Observable<
    HasUserEvaluation<HasTenantIds<TenantSummary>>
  >;
  private unsubscribe = new Subject();

  constructor(private taxiCompanyService: TaxiCompanyService) {}

  company: CompaniesData | undefined;

  ngOnInit() {
    this.tenantSummary$
      .pipe(
        switchMap((tenantId) => {
          return this.taxiCompanyService
            .getOwnCompanyData(tenantId.id)
            .toPromise()
            .catch((err) => {
              console.error(err);
              const result: CompaniesData = {
                id: uuidv4(),
                tenantId: tenantId.id,
                bic: 'BIC',
                city: 'City',
                companyName: 'Name',
                email: 'EMail',
                iban: 'IBAN',
                phoneNumber: 'phone',
                registerCourt: 'RegCourt',
                registerNumber: 'RegNum',
                street: 'street',
                taxidNumber: 'TaxID',
                zipcode: 'ZIPCODE',
                deleted: false,
              };
              return result;
            });
        }),
        takeUntil(this.unsubscribe)
      )
      .forEach((evt) => {
        this.company = evt;
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
  }
}
