<div class="container">
  <div class="row">
    <div class="col-sm-6" *ngIf="!selectedJourney">
      <div class="row">
        <div class="col-sm-12">
          <select
            class="form-control"
            id="journeys"
            [(selectedIndex)]="selectedJourneyMonthIdx"
            #j
            (change)="changeJourney(j.selectedIndex)"
            [disabled]="!dataLoaded"
          >
            <option *ngFor="let i of availableJourneys"
              >{{ i.month | monthName }} {{ i.year }}</option
            >
          </select>
        </div>

        <div class="col-sm-12 spacing-top" *ngIf="!feedbackMsg && dataLoaded">
          <ul class="list-group">
            <li
              *ngFor="let journey of journeysUnprocessed; let i = index"
              class="list-group-item"
              (click)="show(journey, 'un' + i)"
              [id]="'un' + i"
            >
              <app-journey-overview
                [journey]="journey"
                [taxiOperations]="(tenantSummary$ | async)?.taxiOperations"
              ></app-journey-overview>
            </li>
            <div
              class="backgroundLine spacing-top clickable"
              (click)="showProcessed = !showProcessed"
              *ngIf="journeysProcessed.length > 0"
            >
              <span>abgearbeitet</span>
            </div>
            <div *ngIf="showProcessed">
              <li
                *ngFor="let journey of journeysProcessed; let i = index"
                class="list-group-item"
                (click)="show(journey, 'pr' + i)"
                [id]="'pr' + i"
              >
                <app-journey-overview
                  [journey]="journey"
                  [taxiOperations]="(tenantSummary$ | async).taxiOperations"
                ></app-journey-overview>
              </li>
            </div>
          </ul>
        </div>

        <div class="col-sm-12 spacing-top" *ngIf="feedbackMsg">
          {{ feedbackMsg }}
        </div>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="selectedJourney">
      <div class="row">
        <div class="col-sm-5">
          <button
            *ngIf="updated"
            type="button"
            class="btn btn-info"
            (click)="modal.show()"
          >
            Zurück
          </button>
          <button
            *ngIf="!updated"
            type="button"
            class="btn btn-info"
            (click)="back()"
          >
            Zurück
          </button>
        </div>
        <div class="col-sm-5">
          <button
            type="button"
            class="btn btn-info"
            (click)="updateSingleJourney()"
          >
            Fahrtbericht abschließen
          </button>
        </div>
        <div class="col-sm-2">
          <button
            type="button"
            class="btn btn-info"
            (click)="notifyOperator()"
            disabled
            *ngIf="false"
          >
            notify operator
          </button>
        </div>
        <div class="col-sm-12 spacing-top">
          <app-admin-journies-detail
            [journey]="selectedJourney"
            (onJourneyUpdate)="updated = $event"
            [tenantSummary$]="tenantSummary$"
          ></app-admin-journies-detail>
        </div>
      </div>
    </div>

    <div
      class="col-sm-6"
      *ngIf="!selectedJourney && journeysUnprocessed.length > 0 && dataLoaded"
    >
      <div>
        Bitte überprüfen Sie zunächst alle fehlgeschlagenen Fahrten, bevor Sie
        den Monatsbericht abschließen.
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="gridCheck"
          [(ngModel)]="canUpdate"
        />
        <label class="form-check-label" for="gridCheck">
          Es wurde alles überprüft!
        </label>
      </div>
      <button
        type="button"
        class="btn btn-info"
        (click)="update()"
        [disabled]="!canUpdate"
      >
        Abschluss
      </button>
    </div>

    <div
      class="col-sm-6"
      *ngIf="
        !selectedJourney &&
        journeysUnprocessed.length == 0 &&
        journeysWaiting.length == 0 &&
        dataLoaded
      "
    >
      <div>
        Es gibt keine zu überprüfenden Fahrten mehr für diesen Monat. Sie können
        sich ein Report-Archiv mit allen Abrechnungen erzeugen lassen.
      </div>
      <button
        type="button"
        class="btn btn-info"
        (click)="generatePDFReport()"
        [disabled]="!canUpdate || !canCreateArchive"
      >
        Report-Archiv erzeugen
      </button>
    </div>

    <div
      class="col-sm-6"
      *ngIf="
        !selectedJourney &&
        journeysUnprocessed.length == 0 &&
        journeysWaiting.length > 0 &&
        dataLoaded
      "
    >
      <div *ngIf="errorsInWaitingJourneys">
        Es wurden fehlerhafte Einträge erkannt, bitte wenden Sie sich an einen
        Administrator.
        <hr />
        Fehlerhafte Einträge:
        <div *ngFor="let i of journeysWaiting">
          <span
            *ngIf="i.processing_error"
            (click)="i.showErr = !i.showErr"
            class="clickable"
            >{{ i.id }}</span
          >
          <div *ngIf="i.showErr" class="error_detail">
            {{ i.processing_error }}
          </div>
        </div>
      </div>

      <div *ngIf="!errorsInWaitingJourneys">
        Es wurden noch nicht alle Fahrten evaluiert, bitte versuchen Sie es in
        ein paar Minuten erneut.
      </div>
    </div>
  </div>
</div>

<app-messagebox #modal>
  <div class="app-messagebox-header">
    Änderungen vorhanden!
  </div>
  <div class="app-messagebox-body">
    Ihre Änderungen sind noch nicht gespeichert! Sie müssen den "Fahrtbericht
    abschließen" um diese zu übernehmen. Nicht gespeicherte Änderungen gehen
    verloren.
  </div>
  <div class="app-messagebox-footer">
    <div class="row">
      <div class="col-sm-7">
        <button
          type="button"
          class="btn btn-warning"
          (click)="back(); modal.hide()"
        >
          Zurück (ohne speichern)
        </button>
      </div>
      <div class="col-sm-5">
        <button type="button" class="btn btn-default" (click)="modal.hide()">
          Abbrechen
        </button>
      </div>
    </div>
  </div>
</app-messagebox>
