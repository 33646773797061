import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ClerkService, CompaniesData } from '@ecsec/ff-taxi-api-ang9';

@Component({
  selector: 'app-company-taxi-detail',
  templateUrl: './company-taxi-detail.component.html',
  styleUrls: ['./company-taxi-detail.component.css'],
})
export class CompanyTaxiDetailComponent implements OnInit {
  @Input() taxi: any;
  @Input() newTaxi!: boolean;
  @Input() company!: CompaniesData;
  @Input() tenantId!: string;

  @Output() onCreated = new EventEmitter<boolean>();

  nonce: string | null = null;
  licensePlate = '';
  dataLoaded = true;
  feedbackMsg = '';

  constructor(private clerkService: ClerkService) {}

  ngOnInit(): void {}

  requestNonce(txt: string): void {
    if (!this.company) {
      return;
    }
    console.log(txt);
    this.clerkService
      .requestTaxiRegistration(this.tenantId, {
        licensePlateNumber: txt,
        companyId: this.company.id,
      })
      .toPromise()
      .then((evt) => {
        console.log(evt);
        this.nonce = window.atob(evt.nonce);
        console.log(this.nonce);
      })
      .catch((evt) => {
        console.log(evt);
      });
  }

  removeTaxi(serial: string): void {
    console.log(serial);
    this.feedbackMsg = 'Das Taxi wird entfernt, bitte warten...';
    this.dataLoaded = false;
    this.clerkService
      .deleteTaxi(serial, this.tenantId)
      .toPromise()
      .then((evt: any) => {
        console.log(evt);
        this.feedbackMsg = '';
        this.dataLoaded = true;
        this.finish();
      })
      .catch((evt: any) => {
        console.log(evt);
        this.feedbackMsg = 'Beim Löschen ist ein Fehler aufgetreten.';
        this.dataLoaded = true;
      });
  }

  finish(): void {
    this.nonce = null;
    this.licensePlate = '';

    this.onCreated.emit(true);
  }
}
