import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  JourneyReportResponse,
  ValidationResult,
} from '@ecsec/ff-journey-api-ang9';
import {
  PublicService as PublicTenantService,
  TenantSummary,
} from '@ecsec/ff-tenant-api-ang9';
import {
  circle,
  control,
  geoJSON,
  Icon,
  latLng,
  LatLng,
  Layer,
  Map,
  marker,
  tileLayer,
  TileLayer,
} from 'leaflet';

import { PassengerService } from '@ecsec/ff-taxi-api-ang9';
import { Geometry } from 'geojson';
import { EMPTY, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { ChosenTenantSummary } from '../../../tenant.service';
import { BorderedTenant, BorderService } from '../../../border.service';

// TODO: use typs to define hue-rotate classes, create method to getNextHue
export type MarkerHueIndex = 0 | 1;

export type MarkerHue =
  | 'hue-rotate0'
  | 'hue-rotate1'
  | 'hue-rotate2'
  | 'hue-rotate3'
  | 'hue-rotate4';

interface MapOptions {
  zoom?: number;
  layers?: Array<TileLayer>;
  center?: LatLng;
}

@Component({
  selector: 'app-admin-journies-detail',
  templateUrl: './admin-journies-detail.component.html',
  styleUrls: ['./admin-journies-detail.component.css'],
})
export class AdminJourniesDetailComponent implements OnInit, OnDestroy {
  unsubscribe = new Subject();
  validationReportResult: never;

  selectedSubReport: ValidationResult | undefined;

  @Output() onJourneyUpdate = new EventEmitter<boolean>();

  @Input() journey!: JourneyReportResponse;

  @Input() tenantSummary$!: Observable<ChosenTenantSummary>;

  world = [
    [-180, 90],
    [180, 90],
    [180, -90],
    [-180, -90],
  ];

  options: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }),
    ],
  };
  layers: Array<Layer> = [];

  private map: Map | undefined;

  constructor(private borderService: BorderService) {}

  ngOnInit() {
    this.tenantSummary$.pipe(takeUntil(this.unsubscribe)).forEach((tenant) => {
      this.options.zoom = 10;
      this.options.center = latLng(tenant.location.lat, tenant.location.lon);
    });

    this.borderService.borderedTenant.forEach((tenant: BorderedTenant) => {
      this.layers = [];
      const fillOpacity = 0.2 / Math.max(1, tenant.borders.length);
      for (const border of tenant.borders) {
        border.border.then((b) => {
          if (b != 'server-error') {
            this.layers.push(
              geoJSON(b, {
                style(feature) {
                  if (feature) {
                    return { fillOpacity, color: feature.properties.color };
                  } else {
                    return {
                      fillOpacity,
                    };
                  }
                },
              })
            );
          }
        });
      }
    });

    this.addMarker();
  }

  private addWorldToInvert(firstGeometry: Geometry) {
    if (firstGeometry.type === 'MultiPolygon') {
      firstGeometry.coordinates[0].splice(0, 0, this.world);
    }
  }

  onMapReady(readyMap: Map) {
    control
      .scale({
        position: 'bottomleft',
        maxWidth: 100,
        metric: true,
        imperial: false,
        updateWhenIdle: false,
      })
      .addTo(readyMap);
    this.map = readyMap;
  }

  private addMarker() {
    this.addBorderIncidentMarker();

    const journey = this.journey;
    if (!journey) {
      return;
    }
    const validationReport = journey.validationReport;
    if (!validationReport) {
      return;
    }
    const userStartReport = validationReport.userStartReport;
    // UserStart
    if (userStartReport && userStartReport.location) {
      let lat = userStartReport.location.latitude;
      let long = userStartReport.location.longitude;
      let acc = userStartReport.location.accuracy;
      this.addStartPositionMarker(lat, long, acc);
    }

    const userStopReport = validationReport.userStopReport;
    // UserStop
    if (userStopReport && userStopReport.location) {
      let lat = userStopReport.location.latitude;
      let long = userStopReport.location.longitude;
      let acc = userStopReport.location.accuracy;
      this.addStopPositionMarker(lat, long, acc);
    }

    const taxiStartReport = validationReport.taxiStartReport;
    // TaxiStart
    if (taxiStartReport && taxiStartReport.location) {
      let lat = taxiStartReport.location.latitude;
      let long = taxiStartReport.location.longitude;
      let acc = taxiStartReport.location.accuracy;
      this.addStartPositionMarker(lat, long, acc, 1);
    }

    const taxiStopReport = validationReport.taxiStopReport;
    // TaxiStop
    if (taxiStopReport && taxiStopReport.location) {
      let lat = taxiStopReport.location.latitude;
      let long = taxiStopReport.location.longitude;
      let acc = taxiStopReport.location.accuracy;
      this.addStopPositionMarker(lat, long, acc, 1);
    }
  }

  private getColor(hueClassNum: number): string {
    let col = '';
    switch (hueClassNum) {
      case 0:
        col = '#00f';
        break;
      case 1:
        col = '#1d9f1d';
        break;
      default:
        col = '#fff';
    }
    return col;
  }

  private addStartPositionMarker(
    lat: number,
    long: number,
    accuracy: number,
    hueClassNum = 0
  ) {
    let col = this.getColor(hueClassNum);

    if (accuracy) {
      this.layers.push(
        circle(latLng(lat, long), {
          radius: accuracy,
          color: col,
        })
      );
    }

    let icUrl = '../../../assets/report/startLocation.png';

    if (hueClassNum === 1) {
      icUrl = '../../../assets/report/startLocation_2.png';
    }

    this.layers.push(
      marker(latLng(lat, long), {
        icon: new Icon({
          iconSize: [32, 32],
          iconAnchor: [16, 32],
          iconUrl: icUrl,
          className: 'hue-rotate' + hueClassNum,
        }),
        zIndexOffset: 10 * hueClassNum + 10,
      })
    );
  }

  private addStopPositionMarker(
    lat: number,
    long: number,
    accuracy: number,
    hueClassNum = 0
  ) {
    let col = this.getColor(hueClassNum);

    if (accuracy) {
      this.layers.push(
        circle(latLng(lat, long), {
          radius: accuracy,
          color: col,
        })
      );
    }

    let icUrl = '../../../assets/report/stopLocation.png';

    if (hueClassNum === 1) {
      icUrl = '../../../assets/report/stopLocation_2.png';
    }

    this.layers.push(
      marker(latLng(lat, long), {
        icon: new Icon({
          iconSize: [32, 32],
          iconAnchor: [4, 32],
          iconUrl: icUrl,
          className: 'hue-rotate' + hueClassNum,
        }),
        zIndexOffset: 100 * hueClassNum + 100,
      })
    );
  }

  private addBorderIncidentMarker() {
    if (!this.journey) {
      return;
    }
    const validationReport = this.journey.validationReport;
    if (!validationReport) {
      return;
    }
    const borderIncidentReport = validationReport.borderIncidentReport;
    if (!borderIncidentReport || !borderIncidentReport.incidentEntries) {
      return;
    }

    for (
      let index = 0;
      index < borderIncidentReport.incidentEntries.length;
      index++
    ) {
      const entry = borderIncidentReport.incidentEntries[index];

      let hueClassNum = index + 2; // First two are for start/stop location
      let col = this.getColor(hueClassNum);

      const leavePoint = entry.leavePoint;
      if (leavePoint) {
        const lat = leavePoint.latitude;
        const long = leavePoint.longitude;
        const acc = leavePoint.accuracy;

        if (acc) {
          this.layers.push(
            circle(latLng(lat, long), {
              radius: acc,
              color: col,
            })
          );
        }
        this.layers.push(
          marker(latLng(lat, long), {
            icon: new Icon({
              iconSize: [32, 32],
              iconAnchor: [16, 16],
              iconUrl: '../../../assets/report/leave.png',
              className: 'hue-rotate' + hueClassNum,
            }),
            title: '' + index,
          })
        );
      }

      const reenterPoint = entry.reenterPoint;
      if (reenterPoint) {
        const lat = reenterPoint.latitude;
        const long = reenterPoint.longitude;
        const acc = reenterPoint.accuracy;

        let m = marker(latLng(lat, long), {
          icon: new Icon({
            iconSize: [32, 32],
            iconAnchor: [16, 16],
            iconUrl: '../../../assets/report/reenter.png',
            className: 'hue-rotate' + hueClassNum,
          }),
          title: '' + index,
        });

        if (acc) {
          this.layers.push(
            circle(latLng(lat, long), {
              radius: acc,
              color: col,
            })
          );
        }
        this.layers.push(m);
      }
    }
  }

  zoomMapTo(lat: number | LatLng, lng?: number) {
    if (!this.map) {
      return;
    }
    let position: LatLng;
    if (lat instanceof LatLng) {
      position = lat;
    } else if (lng === undefined) {
      console.warn('Must provide either a LatLng or a lat and a long');
      return;
    } else {
      position = latLng(lat, lng);
    }
    this.map.setView(position, Math.max(16, this.map.getZoom()));

    let icUrl = 'assets/report/startLocation_2.png';

    let mk = marker(position, {
      icon: new Icon({
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        iconUrl: icUrl,
      }),
      zIndexOffset: 999999,
    });

    this.layers.push(mk);

    setTimeout(() => {
      let index = this.layers.indexOf(mk);
      this.layers.splice(index, 1);
    }, 1500);
  }

  update(is = true) {
    this.onJourneyUpdate.emit(is);
  }

  ngOnDestroy(): void {
    this.onJourneyUpdate.emit(false);
    this.unsubscribe.next();
  }
}
