import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JourneyReportResponse } from '@ecsec/ff-journey-api-ang9';
import { isNotNullOrUndefined } from '../../../../util/inspection';

@Component({
  selector: 'app-subsidised-price-component',
  templateUrl: './subsidised-price-component.component.html',
  styleUrls: ['./subsidised-price-component.component.css'],
})
export class SubsidisedPriceComponentComponent implements OnInit {
  isEdit = false;

  @Output() priceChanged = new EventEmitter<boolean>();

  constructor() {}

  @Input()
  journey!: JourneyReportResponse;

  @Input()
  editable = false;

  ngOnInit() {}

  get paid(): number | undefined {
    return isNotNullOrUndefined(this.journey)
      ? isNotNullOrUndefined(this.journey.subsidisedPaid)
        ? this.journey.subsidisedPaid
        : this.journey.subsidisedPaidOriginal
      : undefined;
  }

  set paid(value: number | undefined) {
    this.journey.subsidisedPaid = value;
  }

  edit() {
    this.isEdit = true;
  }

  finish(event: any) {
    this.isEdit = false;
  }

  update(event: any) {
    let hasChanged = false;
    if (
      this.journey &&
      this.journey.subsidisedPaid &&
      this.journey.subsidisedPaid !== this.journey.subsidisedPaidOriginal
    ) {
      hasChanged = true;
    }
    this.priceChanged.emit(hasChanged);
  }
}
