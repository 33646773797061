import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const allowedRoles = next.data.allowedRoles;
    return this.hasRequiredRole(allowedRoles);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const allowedRoles = next.data.allowedRoles;
    return this.hasRequiredRole(allowedRoles);
  }

  private hasRequiredRole(allowedRoles?: Array<string>): boolean {
    if (!allowedRoles) {
      return true;
    }
    const isAuthorized = this.authService.hasAnyScope(allowedRoles);

    if (!isAuthorized) {
      this.router.navigate(['/accessdenied']);
    }

    return isAuthorized;
  }
}
