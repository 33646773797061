import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {
  ClerkService,
  PassengerOrder,
  PassengerResponse,
} from '@ecsec/ff-passenger-api-ang9';
import { Observable, of, scheduled, SchedulerLike, Subject } from 'rxjs';
import {
  debounce,
  debounceTime,
  distinctUntilChanged,
  filter,
} from 'rxjs/operators';

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.css'],
})
export class SearchUserComponent implements OnInit, OnDestroy {
  @Output() onSelected = new EventEmitter<PassengerResponse>();
  @Output() onUnSelected = new EventEmitter<boolean>();

  @Input() withoutCompany = false;
  @Input() label = 'Besitzer';
  @Input() tenantId!: string;

  private limit = 15;
  private offset = 0;
  private order: PassengerOrder = PassengerOrder.Asc;

  query$ = new Subject<string>();

  $foundUsers: Subject<Array<PassengerResponse>> = new Subject();

  constructor(private passengerService: ClerkService) {
    this.query$.pipe(debounceTime(200), distinctUntilChanged()).subscribe({
      next: (query) => {
        this.searchUser(query);
      },
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.onUnSelected.emit(true);
  }

  searchUser(query: string) {
    console.log('searchUser: ', query);
    this.passengerService
      .passengerSearch(
        this.tenantId,
        this.limit,
        this.offset,
        this.order,
        query
      )
      .toPromise()
      .then((evt) => {
        console.log(evt);
        this.$foundUsers.next(evt.passengers || []);
      })
      .catch((evt: any) => console.log(evt));
  }

  selectUser(user: PassengerResponse) {
    console.log('Selecting user:', user);
    if (user) {
      this.onSelected.emit(user);
    }
  }
}
