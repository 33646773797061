import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { NgForm } from '@angular/forms';
import { PassengerResponse } from '@ecsec/ff-passenger-api-ang9';
import {
  ClerkService as HttpClerkService,
  CompaniesData,
} from '@ecsec/ff-taxi-api-ang9';
import { v4 as uuidv4 } from 'uuid';
import { TenantService } from '../../../tenant.service';

@Component({
  selector: 'app-company-detail-addnew',
  templateUrl: './company-detail-addnew.component.html',
  styleUrls: ['./company-detail-addnew.component.css'],
})
export class CompanyDetailAddnewComponent implements OnInit, OnDestroy {
  private query: string | undefined;

  public supportCompanyOwner = false;

  foundUsers: Array<PassengerResponse> = [];
  usedUser: PassengerResponse | undefined;
  companyToCreate: Omit<CompaniesData, 'id' | 'tenantId' | 'deleted'> = {};
  error_msg = '';

  step = 1;

  @Output() onSelected = new EventEmitter<PassengerResponse>();
  @Output() onUnSelected = new EventEmitter<boolean>();
  @Output() onCompanyCreated = new EventEmitter<boolean>();

  @Input() newCompany = false;
  @Input() tenantId!: string;

  constructor(private clerkService: HttpClerkService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.onUnSelected.emit(true);
  }

  unselect() {
    this.onUnSelected.emit(true);
  }

  selectUser(user: PassengerResponse) {
    console.log(user);
    this.error_msg = '';
    this.usedUser = user;
    this.onSelected.emit(this.usedUser);

    if (this.newCompany) {
      this.step++;
    } else {
      this.step = 0;
    }
    if (user) {
      this.companyToCreate.ownerId = '' + this.usedUser?.id;
    } else {
      this.companyToCreate.ownerId = undefined;
    }
    // this.companyToCreate.email = this.usedUser.email
  }

  isInputValid(formValidation: NgForm) {
    if (formValidation.invalid) {
      this.error_msg = 'Bitte alle Felder ausfüllen!';
      return formValidation.valid;
    }
    this.error_msg = '';
    return formValidation.valid;
  }

  createCompany(formValidation: NgForm) {
    if (this.isInputValid(formValidation)) {
      const newCompany: CompaniesData = {
        ...this.companyToCreate,
        tenantId: this.tenantId,
        deleted: false,
        id: uuidv4(),
      };

      this.clerkService
        .createCompany(this.tenantId, newCompany)
        .toPromise()
        .then((evt: any) => {
          console.log(evt);
          this.error_msg = 'Unternehmen wurde erfolgreich angelegt.';
          this.onUnSelected.emit(true);
          this.onCompanyCreated.emit(true);
        })
        .catch((evt: any) => {
          this.error_msg = 'Unternehmen konnte nicht angelegt werden.';
          this.onUnSelected.emit(true);
        });
    }
  }
}
