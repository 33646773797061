import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JourneyReportResponse } from '@ecsec/ff-journey-api-ang9';
import { TaxiOperationsResponse } from '@ecsec/ff-tenant-api-ang9';
import { asCorrectedPrice, isNotUndefined } from '../../../../util/inspection';

@Component({
  selector: 'app-journey-pricing-component',
  templateUrl: './journey-pricing-component.component.html',
  styleUrls: ['./journey-pricing-component.component.css'],
})
export class JourneyPricingComponentComponent implements OnInit {
  @Input() journey!: JourneyReportResponse;
  @Input() taxiOperations!: TaxiOperationsResponse;
  @Input() editable: boolean = false;
  @Output() valuesChanged = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onPriceChange(newPrice: number) {
    console.log('onPriceChange', newPrice);
    if (this.journey) {
      this.journey.totalPaid = newPrice;
      this.valuesChanged.next(true);
      this.journey.subsidisedPaid = this.calculateSubsidy(newPrice);
    }
  }

  onSubsidisedPriceChange(newSubsidisedPrice: number) {
    console.log('onSubsidiesedPriceChange', newSubsidisedPrice);
    if (this.journey) {
      this.journey.subsidisedPaid = newSubsidisedPrice;
      this.valuesChanged.next(true);
    }
  }

  private calculateSubsidy(totalPrice: number): number | undefined {
    const checkTotalPrice = Number(totalPrice);
    if (isNaN(checkTotalPrice)) {
      return undefined;
    }
    const subsidyFactor =
      this.taxiOperations && this.taxiOperations.subsidyFactor
        ? this.taxiOperations.subsidyFactor
        : 0.5;
    const subsidyCap =
      this.taxiOperations && this.taxiOperations.subsidyCap
        ? asCorrectedPrice(this.taxiOperations.subsidyCap / 100)
        : 999999.99;
    return asCorrectedPrice(
      Math.min(checkTotalPrice * subsidyFactor, subsidyCap)
    );
  }
}
