import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CompaniesComponent } from './admin/companies/companies.component';
import { AdminJourniesComponent } from './admin/journeys/admin-journies/admin-journies.component';
import { ReportOverviewComponent } from './admin/reports/report-overview/report-overview.component';
import { StatisticsComponent } from './admin/reports/statistics/statistics.component';
import { UserSettingsComponent } from './admin/user/user-settings/user-settings.component';
import { AppComponent } from './app.component';
import { CLERK, COMPANY, SALESPERSON, Scope } from './auth.service';
import { AuthorizationGuard } from './authorization.guard';
import { CompanyOverviewComponent } from './company/company-overview/company-overview.component';
import { TaxiDetailComponent } from './company/taxi/taxi-detail/taxi-detail.component';
import { TaxiOverviewComponent } from './company/taxi/taxi-overview/taxi-overview.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { LogoutpageComponent } from './logoutpage/logoutpage.component';
import { TenantSelectionComponent } from './tenant-selection/tenant-selection.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthorizationGuard],
    children: [
      { path: '', redirectTo: '/start', pathMatch: 'full' },
      { path: 'start', component: LandingpageComponent },
      { path: 'tenant-selection', component: TenantSelectionComponent },
      { path: 'logout', component: LogoutpageComponent },

      {
        path: 'overview',
        component: CompanyOverviewComponent,
        data: {
          allowedRoles: [COMPANY, SALESPERSON],
        },
      },
      {
        path: 'taxis',
        component: TaxiOverviewComponent,
        data: {
          allowedRoles: [COMPANY, SALESPERSON],
        },
      },
      {
        path: 'taxis/:id',
        component: TaxiOverviewComponent,
        data: {
          allowedRoles: [COMPANY, SALESPERSON],
        },
      },

      {
        path: 'companies',
        component: CompaniesComponent,
        data: {
          allowedRoles: [CLERK, SALESPERSON],
        },
      },
      {
        path: 'companies/:id',
        component: CompaniesComponent,
        data: {
          allowedRoles: [CLERK, SALESPERSON],
        },
      },
      {
        path: 'journeys',
        component: AdminJourniesComponent,
        data: {
          allowedRoles: [CLERK, SALESPERSON],
        },
      },
      {
        path: 'journeys/:idx',
        component: AdminJourniesComponent,
        data: {
          allowedRoles: [CLERK, SALESPERSON],
        },
      },
      {
        path: 'reports',
        component: ReportOverviewComponent,
        data: {
          allowedRoles: [CLERK, SALESPERSON],
        },
      },
      {
        path: 'analysis',
        component: StatisticsComponent,
        data: {
          allowedRoles: [CLERK, SALESPERSON],
        },
      },

      {
        path: 'user_settings',
        component: UserSettingsComponent,
        data: {
          allowedRoles: [CLERK, SALESPERSON],
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
