<li *ngIf="result?.major?.endsWith('failed')">
  {{ infoText }}
  <div *ngIf="show">
    <form>
      <div class="form-group row">
        <textarea
          class="form-control col-sm-9"
          type="text"
          name="result.message"
          disabled
          >{{ result.message }}</textarea
        >
      </div>
    </form>
  </div>
</li>
