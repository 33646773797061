import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export type RetryStrategyConfig = {
  maxRetryAttempts?: number;
  scalingDurationMilliseconds?: number;
  excludedStatusCodes?: Array<number>;
};

export type MayHaveStatus = {
  status?: number;
};

export function genericRetryStrategy<T extends MayHaveStatus>({
  maxRetryAttempts = 3,
  scalingDurationMilliseconds = 1000,
  excludedStatusCodes = [],
}: RetryStrategyConfig = {}) {
  return (attempts: Observable<T>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met or response is a status code we don't wish to retry, throw error
        if (
          retryAttempt > maxRetryAttempts ||
          (error.status && excludedStatusCodes.find((e) => e === error.status))
        ) {
          return throwError(error);
        }
        // retry after 1s, 2s, etc...

        return timer(retryAttempt * scalingDurationMilliseconds);
      })
    );
  };
}
