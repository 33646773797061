<div class="overview">
  <form>
    <div class="form-group row">
      <label for="inputName" class="col-sm-2 col-form-label">Unternehmen</label>
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="inputName"
          placeholder="{{ company?.companyName }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputStreet" class="col-sm-2 col-form-label">Straße</label>
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="inputStreet"
          placeholder="{{ company?.street }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputZipcode" class="col-sm-2 col-form-label">PLZ</label>
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="inputZipcode"
          placeholder="{{ company?.zipcode }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputCity" class="col-sm-2 col-form-label">Stadt</label>
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="inputCity"
          placeholder="{{ company?.city }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPhone" class="col-sm-2 col-form-label"
        >Telefonnummer</label
      >
      <div class="col-sm-10">
        <input
          type="tel"
          class="form-control"
          id="inputPhone"
          placeholder="{{ company?.phoneNumber }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail" class="col-sm-2 col-form-label">E-Mail</label>
      <div class="col-sm-10">
        <input
          type="email"
          class="form-control"
          id="inputEmail"
          placeholder="{{ company?.email }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputIBAN" class="col-sm-2 col-form-label">IBAN</label>
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="inputIBAN"
          placeholder="{{ company?.iban }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputBIC" class="col-sm-2 col-form-label">BIC</label>
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="inputBIC"
          placeholder="{{ company?.bic }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputTaxID" class="col-sm-2 col-form-label"
        >Steuernummer</label
      >
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="inputTaxId"
          placeholder="{{ company?.taxidNumber }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputRegCourt" class="col-sm-2 col-form-label"
        >Registergericht</label
      >
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="inputRegCourt"
          placeholder="{{ company?.registerCourt }}"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputRegNum" class="col-sm-2 col-form-label"
        >Registernummer</label
      >
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="inputRegNum"
          placeholder="{{ company?.registerNumber }}"
          disabled
        />
      </div>
    </div>
  </form>
</div>
