import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import {
  AuthService,
  CLERK,
  COMPANY,
  SALESPERSON,
  Scope,
} from './auth.service';

import { combineLatest } from 'rxjs';
import { filter, flatMap, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { TenantService } from './tenant.service';
import { AuthenticatedUserService } from './user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'FiftyFifty';
  logoutLabel = 'Logout';
  version = environment.version;
  isCollapsed = false;
  constructor(
    private authService: AuthService,
    private oauthService: OAuthService,
    private userService: AuthenticatedUserService,
    private router: Router,
    private tenantService: TenantService
  ) {
    this.configureWithNewConfigApi();
  }

  isLoggedIn() {
    return this.userService.user.pipe(
      map((user) => user.identity !== 'anonymous')
    );
  }

  userIdentityType() {
    return this.userService.user.pipe(map((user) => user.identity));
  }

  isTaxiOwner() {
    return this.tenantService.chosenTenantSummary.pipe(
      map((v) => {
        if (v === undefined) {
          return false;
        } else {
          const { user } = v;
          const companyScopes =
            user.identity !== 'authenticated'
              ? undefined
              : user.assignmentsByScope.get(COMPANY);
          return companyScopes === undefined
            ? undefined
            : companyScopes.size > 0;
        }
      })
    );
  }

  hasScope(str: string) {
    return this.authService.hasScope(str);
  }

  login() {
    this.oauthService.initImplicitFlow();
  }

  get myCurrentTenant() {
    return combineLatest([
      this.tenantService.chosenTenantSummary,
      this.userService.user,
    ]).pipe(
      map((values) => {
        const [v, user] = values;
        if (!v) {
          if (user.identity === 'authenticated') {
            return { show: false, ids: new Set(), name: 'Keine Region' };
          } else {
            return { show: false };
          }
        } else {
          return {
            ...v,
            show: true,
          };
        }
      })
    );
  }
  get availableTenants() {
    return this.tenantService.myTenants;
  }
  get hasChosenTenant() {
    return this.tenantService.hasChosenTenant;
  }
  get hasMultipleTenants() {
    return this.tenantService.hasMultipleTenants;
  }
  get canManageTenant() {
    return this.tenantService.chosenTenantSummary.pipe(
      map((t) => {
        if (!t) {
          return false;
        }
        const { user } = t;
        if (user.overview.has(SALESPERSON)) {
          return true;
        }
        const assignments = user.assignmentsByTenant.get(t.id);
        return assignments ? assignments.has(CLERK) : false;
      })
    );
  }

  get hasTaxiCompany() {
    return this.tenantService.chosenTenantSummary.pipe(
      map((t) => {
        if (!t) {
          return false;
        }
        const assignments = t.user.assignmentsByTenant.get(t.id);
        return assignments ? assignments.has(COMPANY) : false;
      })
    );
  }
  effectiveLogoutLabel() {
    return this.userService.user.pipe(
      map((user) => {
        if (user.identity === 'anonymous') {
          return this.logoutLabel;
        } else {
          if (user.overview.has(SALESPERSON)) {
            return `${this.logoutLabel} - Salesperson`;
          } else if (user.overview.has(CLERK)) {
            return `${this.logoutLabel} - Admin`;
          } else {
            return this.logoutLabel;
          }
        }
      })
    );
  }

  logout() {
    this.oauthService.logOut(true);
    this.router.navigate(['/logout']);
  }

  private configureWithNewConfigApi() {
    this.oauthService.configure({
      // Url of the Identity Provider
      loginUrl: environment.loginUrl,

      // URL of the SPA to redirect the user to after login
      redirectUri: window.location.origin,

      // The SPA's id. The SPA is registerd with this id at the auth-server
      clientId: 'fifty-fifty',
      oidc: false,
      showDebugInformation: true,
      timeoutFactor: 1,

      // set the scope for the permissions the client should request
      // The first three are defined by OIDC. The 4th is a usecase-specific one
      scope: 'auth offline_access TAXI_OWNER SALESPERSON CLERK',
    });
    this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.oauthService.events.subscribe((e) => {
      console.debug('oauth/oidc event', e);
      if (e.type === 'token_expires') {
        this.logout();
      }
    });
    this.oauthService.tryLoginImplicitFlow();
  }
}
