<div
  *ngIf="!detail"
  class="row"
  [class.journey_validation_failed]="
    journey.validationReport.result.major.endsWith('failed')
  "
  [class.journey_validation_ok]="
    journey.validationReport.result.major.endsWith('ok')
  "
  [class.journey_validation_not-accepted]="
    journey.validationReport.result.major.endsWith('not-accepted')
  "
>
  <div class="col-sm-6">{{ journey.taxiCompany }}</div>
  <div class="col-sm-6">
    <app-journey-pricing-component
      [journey]="journey"
      [taxiOperations]="taxiOperations"
    >
    </app-journey-pricing-component>
  </div>
  <div class="col-sm-12">
    Datum: {{ journey.startTime | date: "dd.MM.yyyy 'um' HH:mm" }}
  </div>
  <div
    class="col-sm-12"
    *ngIf="journey.firstnamePassenger || journey.lastnamePassenger"
  >
    Fahrgast: {{ journey.firstnamePassenger }} {{ journey.lastnamePassenger }}
  </div>
  <div
    class="col-sm-12"
    *ngIf="!journey.firstnamePassenger && !journey.lastnamePassenger"
  >
    Fahrgast:
    <span class="warning">Unbekannter Fahrgast</span>
  </div>
  <div class="col-sm-12">Start: {{ journey.startLocation }}</div>
  <div class="col-sm-12">Stopp: {{ journey.endLocation }}</div>
</div>

<div
  *ngIf="detail"
  class="row spacing"
  [class.journey_validation_failed]="
    journey.validationReport.result.major.endsWith('failed')
  "
  [class.journey_validation_ok]="
    journey.validationReport.result.major.endsWith('ok')
  "
  [class.journey_validation_not-accepted]="
    journey.validationReport.result.major.endsWith('not-accepted')
  "
>
  <div class="col-sm-12" id="journey_id">ID: {{ journey.id }}</div>
  <div class="col-sm-6">{{ journey.taxiCompany }}</div>
  <div class="col-sm-6">
    <app-journey-pricing-component
      [journey]="journey"
      [taxiOperations]="taxiOperations"
      [editable]="true"
      (valuesChanged)="update($event)"
    >
    </app-journey-pricing-component>
  </div>
  <div class="col-sm-12">
    Fahrgast:
    <div class="row">
      <div class="col-sm-1"></div>
      <div
        class="col-sm-11"
        *ngIf="journey.firstnamePassenger || journey.lastnamePassenger"
      >
        {{ journey.firstnamePassenger }} {{ journey.lastnamePassenger }}
      </div>
      <div
        class="col-sm-11 warning"
        *ngIf="!journey.firstnamePassenger && !journey.lastnamePassenger"
      >
        Unbekannter Fahrgast
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1"></div>

      <div class="col-sm-7 info">
        {{ passengerString }}
        <span class="editpen" (click)="editPassenger = !editPassenger">
          <img width="16px" height="16px" src="assets/report/edit.png" />
        </span>
      </div>

      <div class="col-sm-3" *ngIf="editPassenger">
        <input
          name="ourneynumPassengers"
          [(ngModel)]="journey.numPassengers"
          type="text"
          class="form-control"
          id="numPassengers"
          type="number"
          step="1"
          min="1"
          max="9"
        />
      </div>
    </div>
  </div>

  <div class="col-sm-12 spacing-top">
    <div class="row">
      <div
        class="col-sm-7 clickable"
        (click)="
          zoomMapTo(
            journey.validationReport.taxiStartReport.location.latitude,
            journey.validationReport.taxiStartReport.location.longitude
          )
        "
        *ngIf="
          journey.validationReport.taxiStartReport &&
          journey.validationReport.taxiStartReport.time
        "
      >
        <span class="infoText">Taxi Start:</span>
        <div class="row">
          <div class="col-sm-1 positionIcon">
            <img src="assets/report/startLocation_2.png" class="hue-rotate1" />
          </div>
          <div class="col-sm-10">
            <div class="row">
              <div class="col-sm-12">
                {{ journey.startLocation }}
              </div>
              <div class="col-sm-12">
                {{
                  journey.validationReport.taxiStartReport.time
                    | date: "dd.MM.yyyy 'um' HH:mm"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-sm-5 clickable"
        (click)="
          zoomMapTo(
            journey.validationReport.userStartReport.location.latitude,
            journey.validationReport.userStartReport.location.longitude
          )
        "
        *ngIf="
          journey.validationReport.userStartReport &&
          journey.validationReport.userStartReport.time
        "
      >
        <span class="infoText">Fahrgast Start:</span>
        <div class="row">
          <div class="col-sm-1 positionIcon">
            <img src="assets/report/startLocation.png" class="hue-rotate0" />
          </div>
          <div class="col-sm-10">
            <div class="row">
              <div class="col-sm-12">
                {{
                  journey.validationReport.userStartReport.time
                    | date: 'dd.MM.yyyy'
                }}
              </div>
              <div class="col-sm-12">
                {{
                  journey.validationReport.userStartReport.time
                    | date: "'um' HH:mm"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-12 spacing">
    <div class="row">
      <div
        class="col-sm-7 clickable"
        (click)="
          zoomMapTo(
            journey.validationReport.taxiStopReport.location.latitude,
            journey.validationReport.taxiStopReport.location.longitude
          )
        "
        *ngIf="
          journey.validationReport.taxiStopReport &&
          journey.validationReport.taxiStopReport.time
        "
      >
        <span class="infoText">Taxi Stopp:</span>
        <div class="row">
          <div class="col-sm-1 positionIcon">
            <img src="assets/report/stopLocation_2.png" class="hue-rotate1" />
          </div>
          <div class="col-sm-10">
            <div class="row">
              <div class="col-sm-12">
                {{ journey.endLocation }}
              </div>
              <div class="col-sm-12">
                {{
                  journey.validationReport.taxiStopReport.time
                    | date: "dd.MM.yyyy 'um' HH:mm"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-sm-5 clickable"
        (click)="
          zoomMapTo(
            journey.validationReport.userStopReport.location.latitude,
            journey.validationReport.userStopReport.location.longitude
          )
        "
        *ngIf="
          journey.validationReport.userStopReport &&
          journey.validationReport.userStopReport.time
        "
      >
        <span class="infoText">Fahrgast Stopp:</span>
        <div class="row">
          <div class="col-sm-1 positionIcon">
            <img src="assets/report/stopLocation.png" class="hue-rotate0" />
          </div>
          <div class="col-sm-10">
            <div class="row">
              <div class="col-sm-12">
                {{
                  journey.validationReport.userStopReport.time
                    | date: 'dd.MM.yyyy'
                }}
              </div>
              <div class="col-sm-12">
                {{
                  journey.validationReport.userStopReport.time
                    | date: "'um' HH:mm"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 spacing-top">
      <div class="row">
        <div class="col-sm-7" *ngIf="driverJourneyDuration">
          Fahrdauer: {{ driverJourneyDuration }}
        </div>
        <div class="col-sm-5" *ngIf="userJourneyDuration">
          Fahrdauer: {{ userJourneyDuration }}
        </div>
      </div>
    </div>

    <div class="col-sm-12 spacing" *ngIf="journey.complaintMsg">
      Beschwerde über den Fahrgast:
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-11">
          {{ journey.complaintMsg }}
        </div>
      </div>
    </div>
  </div>
</div>
