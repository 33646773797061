<div class="version">
  {{ version }}
</div>
<div>
  <nav class="navbar navbar-expand-md p-3 mb-2 navbar-light">
    <div class="navbar-brand">
      <img
        src="./assets/Taxi-Start.jpg"
        width="120"
        class="d-inline-block align-middle"
      />{{ this.title }}<img />
    </div>

    <button
      class="navbar-toggler"
      type="button"
      [class.active]="!isCollapsed"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      [ngbCollapse]="isCollapsed"
    >
      <ul
        class="nav navbar-nav mr-auto text-center ml-lg-0 d-lg-flex align-items-center"
      >
        <li class="nav-item" *ngIf="!(isLoggedIn() | async)">
          <button class="btn btn-info" (click)="login()">
            Login
          </button>
        </li>
        <li class="nav-item" *ngIf="hasTaxiCompany | async">
          <a
            class="nav-link"
            routerLink="/overview"
            routerLinkActive="active is-active"
            >Stammdaten</a
          >
        </li>
        <li class="nav-item" *ngIf="hasTaxiCompany | async">
          <a
            class="nav-link"
            routerLink="/taxis"
            routerLinkActive="active is-active"
            >Taxis</a
          >
        </li>
        <li class="nav-item" *ngIf="canManageTenant | async">
          <a
            class="nav-link"
            routerLink="/companies"
            routerLinkActive="active is-active"
            >Taxiunternehmen</a
          >
        </li>
        <li class="nav-item" *ngIf="canManageTenant | async">
          <a
            class="nav-link"
            routerLink="/journeys"
            routerLinkActive="active is-active"
            >Taxifahrten</a
          >
        </li>
        <li class="nav-item" *ngIf="canManageTenant | async">
          <a
            class="nav-link"
            routerLink="/reports"
            routerLinkActive="active is-active"
            >Abrechnungen</a
          >
        </li>
        <li class="nav-item" *ngIf="canManageTenant | async">
          <a
            class="nav-link"
            routerLink="/analysis"
            routerLinkActive="active is-active"
            >Analyse</a
          >
        </li>
        <li class="nav-item" *ngIf="canManageTenant | async">
          <a
            class="nav-link"
            routerLink="/user_settings"
            routerLinkActive="active is-active"
            >Nutzerverwaltung</a
          >
        </li>
      </ul>
      <ul class="nav navbar-nav ml-auto ml-lg-0 d-lg-flex align-items-center">
        <li
          class="nav-item"
          *ngIf="myCurrentTenant | async as tenantSelection"
          [attr.disabled]="
            !tenantSelection.show || tenantSelection.ids.size < 2
          "
        >
          <a
            class="nav-link"
            routerLink="/tenant-selection"
            routerLinkActive="active is-active"
            *ngIf="tenantSelection.show && tenantSelection.ids.size > 1"
            >{{ tenantSelection.name }}</a
          >
          <ng-template #disabledTenantLink>
            <div
              class="nav-link"
              (click)="login()"
              *ngIf="tenantSelection.name"
            >
              {{ tenantSelection.name }}
            </div>
          </ng-template>
        </li>

        <li class="nav-item" *ngIf="isLoggedIn() | async">
          <a
            class="nav-link"
            (click)="logout()"
            routerLink="/logout"
            routerLinkActive="active is-active"
            >Logout</a
          >
        </li>
      </ul>
    </div>
  </nav>
</div>
<main class="overview">
  <router-outlet></router-outlet>
</main>
