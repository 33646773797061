<app-company-detail-addnew
  (onSelected)="selectUser($event)"
  (onCompanyCreated)="created.emit(true)"
  [newCompany]="newCompany"
  [tenantId]="tenantId"
  *ngIf="visible || newCompany"
>
</app-company-detail-addnew>

<div *ngIf="company">
  <form #editForm="ngForm">
    <div class="form-group row" *ngIf="!visible && showUserSelection">
      <div class="col-sm-10" *ngIf="users && users.firstName">
        <div class="col-sm-12">{{ users.firstName }} {{ users.lastName }}</div>
        <div class="col-sm-12">
          {{ users.email }}
        </div>
      </div>

      <div class="col-sm-10" *ngIf="users && !users.firstName">
        <div class="col-sm-12 info_text">
          Dieses Unternehmen ist keinem Nutzer zugewiesen.
        </div>
      </div>

      <div class="col-sm-2">
        <button type="button" class="btn btn-info" (click)="changeUser()">
          Ändern
        </button>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputName" class="col-sm-3 col-form-label">Unternehmen</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputName"
          name="companyName"
          [(ngModel)]="company.companyName"
          #companyName="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputStreet" class="col-sm-3 col-form-label">Straße</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputStreet"
          name="street"
          [(ngModel)]="company.street"
          #street="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputZipcode" class="col-sm-3 col-form-label">PLZ</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          maxlength="5"
          type="text"
          class="form-control"
          id="inputZipcode"
          name="zipcode"
          [(ngModel)]="company.zipcode"
          #zipcode="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputCity" class="col-sm-3 col-form-label">Stadt</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputCity"
          name="city"
          [(ngModel)]="company.city"
          #city="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPhone" class="col-sm-3 col-form-label"
        >Telefonnummer</label
      >
      <div class="col-sm-9">
        <input
          minlength="1"
          type="tel"
          class="form-control"
          id="inputPhone"
          name="phoneNumber"
          [(ngModel)]="company.phoneNumber"
          #phoneNumber="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail" class="col-sm-3 col-form-label">E-Mail</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="email"
          class="form-control"
          id="inputEmail"
          name="email"
          [(ngModel)]="company.email"
          #email="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputIBAN" class="col-sm-3 col-form-label">IBAN</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputIBAN"
          name="iban"
          [(ngModel)]="company.iban"
          #iban="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputBIC" class="col-sm-3 col-form-label">BIC</label>
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputBIC"
          name="bic"
          [(ngModel)]="company.bic"
          #bic="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputTaxID" class="col-sm-3 col-form-label"
        >Steuernummer</label
      >
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputTaxId"
          name="taxidNumber"
          [(ngModel)]="company.taxidNumber"
          #taxidNumber="ngModel"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputRegCourt" class="col-sm-3 col-form-label"
        >Registergericht</label
      >
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputRegCourt"
          name="registerCourt"
          [(ngModel)]="company.registerCourt"
          #registerCourt="ngModel"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputRegNum" class="col-sm-3 col-form-label"
        >Registernummer</label
      >
      <div class="col-sm-9">
        <input
          minlength="1"
          type="text"
          class="form-control"
          id="inputRegNum"
          name="registerNumber"
          [(ngModel)]="company.registerNumber"
          #registerNumber="ngModel"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12" [class.error]="error_msg && editForm.invalid">
        <span *ngIf="editForm.invalid || error_msg">{{ error_msg }}</span>
      </div>
      <div class="col-sm-7">
        <button
          type="button"
          class="btn btn-info"
          (click)="updateCompanyData(editForm)"
        >
          Update
        </button>
      </div>
      <div class="col-sm-5">
        <button type="button" class="btn btn-danger" (click)="delete()">
          Unternehmen löschen
        </button>
      </div>
    </div>
  </form>
</div>
