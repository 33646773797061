<div class="container">
  <div class="row">
    <div class="col-sm-4" *ngIf="chartData$ | async as chartData">
      <fieldset>
        <div class="row">
          <legend>Mögliche Analyse</legend>
        </div>
        <div *ngFor="let entry of entries" class="row">
          <label for="journeys_completed_by_month">
            <input
              type="radio"
              name="statistikTyp"
              [checked]="chartData.selection === entry.type"
              [value]="entry.type"
              (change)="onSelectionChange(entry)"
            />
            {{ entry.description }}
          </label>
        </div>
      </fieldset>
      <div class="row">
        <button
          type="button"
          class="btn btn-info"
          (click)="downloadStatistics(chartData)"
        >
          Herunterladen
        </button>
      </div>
    </div>
    <div class="col-sm-8">
      <canvas #statisticsCanvas></canvas>
    </div>
  </div>
</div>
