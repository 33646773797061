import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthService {
  constructor(private oauthService: OAuthService) {}

  hasScope(str: string) {
    const sarr: Array<string> = this.oauthService.getGrantedScopes() as Array<
      string
    >;
    if (!sarr) {
      return false;
    }

    for (let s in sarr) {
      if (sarr[s] === str) {
        return true;
      }
    }

    return false;
  }

  hasAnyScope(scopes: Array<string>) {
    const sarr: Array<string> = this.oauthService.getGrantedScopes() as Array<
      string
    >;

    if (!sarr) {
      return false;
    }

    for (const scope of scopes) {
      if (sarr.includes(scope)) {
        return true;
      }
    }
    return false;
  }
}

export const PASSENGER = 'PASSENGER' as const;
export type PASSENGER = typeof PASSENGER;
export const COMPANY = 'COMPANY' as const;
export type COMPANY = typeof COMPANY;
export const CLERK = 'CLERK' as const;
export type CLERK = typeof CLERK;
export const SALESPERSON = 'SALESPERSON' as const;
export type SALESPERSON = typeof SALESPERSON;

export const Scope = [PASSENGER, COMPANY, CLERK, SALESPERSON] as const;
export type Scope = typeof Scope[number];
