<div *ngIf="taxi">
  <h2>{{ taxi.licensePlate }}</h2>
  <div>
    <form>
      <div class="form-group row">
        <div class="col-sm-12">
          <button
            type="button"
            class="btn btn-danger"
            (click)="deletePendingTaxi(taxi)"
          >
            Löschen
          </button>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <qr-code
            #qrcodeDisplay
            [value]="atob(taxi.challenge)"
            [size]="350"
            canvas="false"
          ></qr-code>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="form-group row" *ngIf="feedbackMsg">
  <div class="col-sm-12">
    {{ feedbackMsg }}
  </div>
</div>
