import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReportService } from '@ecsec/ff-journey-api-ang9';
import * as FileSaver from 'file-saver';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  empty,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  pairwise,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  HasUserEvaluation,
  TenantSelection,
  TenantService,
} from '../../../tenant.service';
import { assertNever } from '../../../util/inspection';
import { Papa } from 'ngx-papaparse';
import { ChartDataset, ChartType } from 'chart.js';

// Register all chart types to prevent tree-shaking problems.
import Chart from 'chart.js/auto';

export type StatisticType =
  | 'journeys_completed_by_month'
  | 'journeys_billed_by_month';

export type StatisticsDescription = {
  type: StatisticType;
  description: string;
};

export type HasChart = {
  chart: Chart;
};

export type ViewModel = HasUserEvaluation<TenantSelection> & {
  data: string;
  selection: StatisticType;
};

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css'],
})
export class StatisticsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('statisticsCanvas', { static: true })
  private statisticsCanvas!: ElementRef;

  public entries: Array<StatisticsDescription> = [
    {
      type: 'journeys_completed_by_month',
      description: 'Abgeschlossene Fahrten',
    },
    {
      type: 'journeys_billed_by_month',
      description: 'Abgerechnete Fahrten',
    },
  ];
  public selection$ = new BehaviorSubject<StatisticType>(this.entries[0].type);
  public statisticsCanvas$ = new ReplaySubject<ElementRef>(1);
  public tenant$!: Observable<HasUserEvaluation<TenantSelection> | undefined>;
  public chartData$!: Observable<ViewModel>;
  public chart$!: Observable<Chart>;
  private currentChart: Chart | undefined;
  readonly unsubscribe = new Subject();

  constructor(
    private tenantService: TenantService,
    private reportService: ReportService,
    private papa: Papa
  ) {}

  ngAfterViewInit(): void {
    this.statisticsCanvas$.next(this.statisticsCanvas);
  }
  ngOnInit(): void {
    this.tenant$ = this.tenantService.chosenTenant;
    this.chartData$ = combineLatest([this.tenant$, this.selection$]).pipe(
      switchMap((values) => {
        const [tenant, selection] = values;
        if (!tenant) {
          return EMPTY;
        }
        return this.fetchStatistics(selection, tenant)
          .then((rawData: any) => {
            let data: Promise<string>;
            if (typeof rawData === 'string') {
              data = Promise.resolve(rawData);
            } else if (rawData instanceof Blob) {
              data = rawData.text();
            } else {
              console.error('Unknown data type', rawData);
              throw new Error('Unbekannte Daten erhalten vom Server.');
            }
            return data;
          })
          .then((data) => {
            return {
              ...tenant,
              selection,
              data,
            };
          });
      }),
      shareReplay(1)
    );
    this.chart$ = combineLatest([this.statisticsCanvas$, this.chartData$]).pipe(
      map((values) => {
        const [canvas, model] = values;
        const { data, selection } = model;
        console.log('Parsing: ', data);
        const parsedData = this.papa.parse(data, {
          delimiter: ';',
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });

        const headers: Array<string> = [...parsedData.meta.fields];
        const colours = ['red', 'blue', 'green', 'yellow', 'orange', 'pink'];
        const labeloffset = 2;
        const dataSets: Array<ChartDataset<'bar', any>> = [];

        for (let index = 2; index < headers.length; index++) {
          dataSets.push({
            label: headers[index],
            data: [],
            backgroundColor: colours[index - labeloffset],
          });
        }
        const labels: Array<string> = [];
        for (const row of parsedData.data) {
          const x = `${row[headers[0]]} ${row[headers[1]]}`;
          labels.unshift(x);
          for (let index = labeloffset; index < headers.length; index++) {
            const y = row[headers[index]];
            dataSets[index - labeloffset].data.unshift(y);
          }
        }
        headers.shift();
        headers.shift();

        const barType: ChartType = 'bar';
        if (this.currentChart) {
          this.currentChart.destroy();
          this.currentChart = undefined;
        }
        const chart: Chart = new Chart(
          canvas.nativeElement as HTMLCanvasElement,
          {
            type: barType,
            data: {
              labels: labels,
              datasets: dataSets,
            },
          }
        );
        return chart;
      }),
      takeUntil(this.unsubscribe)
    );
    this.chart$.forEach((chart) => {
      this.currentChart = chart;
    });
  }

  onSelectionChange(choice: StatisticsDescription) {
    this.selection$.next(choice.type);
  }

  downloadStatistics(choice: ViewModel) {
    let name: string = this.fileName(choice.selection);

    FileSaver.saveAs(
      new Blob([choice.data], {
        type: 'text/csv;charset=utf-8',
        endings: 'native',
      }),
      `FiftyFifty_Analyse_${name}.csv`
    );
  }

  private fetchStatistics(
    choice: StatisticType,
    tenant: HasUserEvaluation<TenantSelection>
  ): Promise<Blob> {
    switch (choice) {
      case 'journeys_billed_by_month':
        return this.reportService
          .billedJourneysByMonth(
            tenant.tenantId,
            ';',
            undefined,
            undefined,
            undefined,
            undefined,
            'body'
          )
          .toPromise();
      case 'journeys_completed_by_month':
        return this.reportService
          .completedJourneysByMonth(
            tenant.tenantId,
            ';',
            undefined,
            undefined,
            undefined,
            undefined,
            'body'
          )
          .toPromise();
      default:
        assertNever(choice);
    }
  }

  private fileName(choice: StatisticType) {
    switch (choice) {
      case 'journeys_billed_by_month':
        return 'Abgerechnete_Fahrten';
      case 'journeys_completed_by_month':
        return 'Abgeschlossene_Fahrten';
      default:
        assertNever(choice);
    }
  }
  private labelName(choice: StatisticType) {
    switch (choice) {
      case 'journeys_billed_by_month':
        return 'Abgerechnete Fahrten';
      case 'journeys_completed_by_month':
        return 'Abgeschlossene Fahrten';
      default:
        assertNever(choice);
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
  }
}
