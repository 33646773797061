import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgForm } from '@angular/forms';
import { PassengerResponse } from '@ecsec/ff-passenger-api-ang9';
import { ClerkService, CompaniesData } from '@ecsec/ff-taxi-api-ang9';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css'],
})
export class CompanyDetailComponent implements OnInit {
  _company: CompaniesData | undefined;
  showUserSelection = false;
  @Input() newCompany: any;

  @Output() created = new EventEmitter<boolean>();

  @Input() tenantId!: string;

  @Input()
  set company(data: CompaniesData | undefined) {
    this._company = data;
    if (data && data.id) {
      this.getUser();
      this.error_msg = '';
      this.clerkService
        .getCompanyData(data.id, data.tenantId)
        .toPromise()
        .then((evt) => {
          this._company = evt;
        });
    }
  }
  get company(): CompaniesData | undefined {
    return this._company;
  }

  searchedQuery = '';
  visible = false;
  users: PassengerResponse | undefined;

  error_msg = '';

  constructor(private clerkService: ClerkService) {}

  ngOnInit() {}

  getUser() {
    if (this._company && this._company.ownerId) {
      console.log('CompanyDetail:getUser: ' + this._company.ownerId);
      // this.adminService
      //   .getUserDataForUser(this._company.ownerId)
      //   .toPromise()
      //   .then((evt) => {
      //     this.users = evt;
      //   })
      //   .catch((evt) => {
      //     console.log(evt);
      //   });
    }
  }

  changeUser() {
    this.visible = true;
  }

  selectUser(data: PassengerResponse) {
    console.log(data);
    if (data && this._company) {
      this.visible = false;
      // TODO: handle assigning owner correctly
      // this._company.ownerId = data.id;

      // this.updateCompanyData()
      this.getUser();
    }
  }

  isInputValid(formValidation: NgForm) {
    if (formValidation.invalid) {
      this.error_msg = 'Bitte alle Felder ausfüllen!';
      return formValidation.valid;
    }
    this.error_msg = '';
    return formValidation.valid;
  }

  updateCompanyData(formValidation: NgForm) {
    console.log(this._company, this.users);

    if (
      this.isInputValid(formValidation) &&
      this._company &&
      this._company.id
    ) {
      this.clerkService
        .updateCompanyData(
          this._company.id,
          this._company.tenantId,
          this._company
        )
        .toPromise()
        .then((evt: any) => {
          this.error_msg = 'Die Daten wurden erfolgreich aktualisiert.';
          console.log(evt);
          this.getUser();
        })
        .catch((evt: any) => {
          this.error_msg = 'Es ist ein Fehler beim Update aufgetreten.';
          console.log(evt);
        });
    }
  }

  delete() {
    if (this._company && this._company.id) {
      this.clerkService
        .deleteCompany(this._company.id, this._company.tenantId)
        .toPromise()
        .then((evt: any) => {
          this.error_msg = 'Das Unternehmen wurde erfolgreich gelöscht.';
          console.log(evt);
          this.getUser();
        })
        .catch((evt: any) => {
          this.error_msg = 'Es ist ein Fehler beim löschen aufgetreten.';
          console.log(evt);
        });
    }
  }
}
