import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  JourneyReportResponse,
  JourneyValidationReport,
} from '@ecsec/ff-journey-api-ang9';
import { TaxiOperationsResponse } from '@ecsec/ff-tenant-api-ang9';
import { LatLng, latLng } from 'leaflet';

const MISSING_DURATION = '';
@Component({
  selector: 'app-journey-overview',
  templateUrl: './journey-overview.component.html',
  styleUrls: ['./journey-overview.component.css'],
})
export class JourneyOverviewComponent implements OnInit {
  @Input() journey!: JourneyReportResponse;
  @Input() taxiOperations!: TaxiOperationsResponse;
  @Input() detail = false;
  @Output() priceChanged = new EventEmitter<boolean>();
  @Output() positionClick = new EventEmitter<LatLng>();

  driverJourneyDuration: string = MISSING_DURATION;
  userJourneyDuration: string = MISSING_DURATION;

  get passengerString(): string {
    if (!this.journey) {
      return '';
    }
    const numPassengers = this.journey.numPassengers;
    if (numPassengers === undefined || numPassengers <= 1) {
      return 'Keine weiteren Fahrgäste';
    }
    return numPassengers === 2
      ? '+' + (numPassengers - 1) + ' Fahrgast'
      : '+' + (numPassengers - 1) + ' Fahrgäste';
  }

  constructor() {}

  ngOnInit() {
    this.calculateDuration();
  }

  private calculateDuration() {
    if (!this.journey) {
      this.driverJourneyDuration = MISSING_DURATION;
      this.userJourneyDuration = MISSING_DURATION;
      return;
    }
    const validationReport = this.journey.validationReport;
    if (
      this.isTimeAvailable(
        'taxiStartReport',
        'taxiStopReport',
        validationReport
      )
    ) {
      const start = validationReport?.taxiStartReport?.time;
      const stop = validationReport?.taxiStopReport?.time;
      let timeDriver = this.difference(start, stop);
      this.driverJourneyDuration = timeDriver;
    }
    if (
      this.isTimeAvailable(
        'userStartReport',
        'userStopReport',
        this.journey.validationReport
      )
    ) {
      const start = validationReport?.userStartReport?.time;
      const stop = validationReport?.userStopReport?.time;

      let timeUser = this.difference(start, stop);

      this.userJourneyDuration = timeUser;
    }
  }

  private difference(start?: Date, stop?: Date) {
    if (!start || !stop) {
      return MISSING_DURATION;
    }
    let driverStart = new Date(start);
    let driverEnd = new Date(stop);
    let driverDuration = driverEnd.valueOf() - driverStart.valueOf();
    let dm = Math.floor(driverDuration / 1000 / 60);
    let timeDriver = this.convertMinsToHrsMins(dm);
    return timeDriver;
  }

  private convertMinsToHrsMins(minutes: number): string {
    let hour = Math.floor(minutes / 60);
    let min = minutes % 60;
    let h = hour < 10 ? '0' + hour : hour;
    let m = min < 10 ? '0' + min : min;
    return h + ':' + m;
  }

  private isTimeAvailable<
    K extends keyof JourneyValidationReport,
    M extends keyof JourneyValidationReport
  >(start: K, end: M, report?: JourneyValidationReport): boolean {
    if (report) {
      const startReport: any = report[start];
      const stopReport: any = report[end];
      return (
        startReport != null &&
        startReport.time != null &&
        stopReport != null &&
        stopReport.time != null
      );
    }
    return false;
  }

  update(is = true) {
    this.priceChanged.emit(is);
  }

  zoomMapTo(lat: number, lng: number) {
    this.positionClick.emit(latLng(lat, lng));
  }
}
