<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <ul class="list-group">
        <li
          [class.highlight]="hightlightStatus[i]"
          *ngFor="let taxi of taxis; let i = index"
          class="list-group-item"
          (click)="show(taxi, i)"
        >
          {{ taxi.licensePlate }}
        </li>
        <li
          [class.highlight]="hightlightStatusNewTaxi"
          class="list-group-item new"
          (click)="newTaxi()"
        >
          Neues Taxi
        </li>
      </ul>
    </div>
    <div class="col-sm-6">
      <app-taxi-detail
        [taxi]="selectedTaxi"
        [newTaxi]="hightlightStatusNewTaxi"
        (onCreated)="reset()"
        [tenantSummary$]="tenantSummary$"
      ></app-taxi-detail>
    </div>
  </div>
</div>
