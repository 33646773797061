import { Component, OnInit } from '@angular/core';
import {
  ChoosableTenant,
  HasUserEvaluation,
  TenantService,
} from '../tenant.service';

@Component({
  selector: 'app-tenant-selection',
  templateUrl: './tenant-selection.component.html',
  styleUrls: ['./tenant-selection.component.css'],
})
export class TenantSelectionComponent implements OnInit {
  constructor(private tenantService: TenantService) {}

  ngOnInit(): void {}

  get availableTenants() {
    return this.tenantService.myTenants;
  }

  onChoose(tenant: HasUserEvaluation<ChoosableTenant>) {
    if (tenant) {
      tenant.choose();
    }
  }
}
