import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  ClerkService,
  CompaniesData,
  PendingTaxiTicketinfo,
} from '@ecsec/ff-taxi-api-ang9';

@Component({
  selector: 'app-company-pending-taxi-detail',
  templateUrl: './company-pending-taxi-detail.component.html',
  styleUrls: ['./company-pending-taxi-detail.component.css'],
})
export class CompanyPendingTaxiDetailComponent implements OnInit {
  @Input() taxi!: PendingTaxiTicketinfo;
  @Input() company!: CompaniesData;
  @Input() tenantId!: string;

  @Output() onDeleted = new EventEmitter<boolean>();
  dataLoaded = true;
  feedbackMsg = '';

  constructor(private clerkService: ClerkService) {}

  ngOnInit(): void {}

  atob(value: string): string {
    return window.atob(value);
  }

  deletePendingTaxi(taxi: PendingTaxiTicketinfo): void {
    if (!this.company) {
      return;
    }
    console.log(taxi);
    this.feedbackMsg = 'Das Taxi wird entfernt, bitte warten...';
    this.dataLoaded = false;
    this.clerkService
      .deleteTaxiRegistrationRequest(
        this.tenantId,
        taxi.challenge,
        this.company.id,
        taxi.licensePlate
      )
      .toPromise()
      .then(() => {
        this.feedbackMsg = '';
        this.dataLoaded = true;
        this.onDeleted.emit(true);
      })
      .catch((evt: any) => {
        console.log(evt);
        this.feedbackMsg = 'Beim Löschen ist ein Fehler aufgetreten.';
        this.dataLoaded = true;
      });
  }
}
