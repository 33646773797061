// tslint:disable-next-line:no-reference
///<reference path="../typings.d.ts"/>

export const default_environment = {
  production: false,
  loginUrl: 'REPLACED_BY_BUILD_IDENTITY_URL/external/oauth/cc/authorize',
  refreshUrl: 'REPLACED_BY_BUILD_IDENTITY_URL/external/oauth/cc/refresh',
  tenantUrl: 'REPLACED_BY_BUILD_TENANT_URL',
  taxiUrl: 'REPLACED_BY_BUILD_TAXI_URL',
  passengerUrl: 'REPLACED_BY_BUILD_PASSENGER_URL',
  journeyUrl: 'REPLACED_BY_BUILD_JOURNEY_URL',
  version: $ENV.VERSION,
};
