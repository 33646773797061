import { Location } from '@angular/common';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PendingTaxiTicketinfo, TaxiTicketInfo } from '@ecsec/ff-taxi-api-ang9';
import {
  ClerkService as HttpClerkService,
  CompaniesData,
} from '@ecsec/ff-taxi-api-ang9';
import {
  combineLatest,
  EMPTY,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  flatMap,
  map,
  share,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { TenantService } from '../../tenant.service';
import { isNotUndefined } from '../../util/inspection';
import { CompanyTaxiOverviewComponent } from './taxi/company-taxi-overview/company-taxi-overview.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css'],
})
export class CompaniesComponent implements OnInit, OnDestroy {
  @ViewChild(CompanyTaxiOverviewComponent, { static: false })
  taxiList!: CompanyTaxiOverviewComponent;

  readonly selectedCompanyId: Observable<string | undefined>;
  readonly tenantId$: Observable<string>;

  private routeSub: Subscription | undefined;
  private refreshCompanyList$ = new ReplaySubject<{}>(1);

  companies: Observable<Array<CompaniesData>>;
  hightlightStatus: Array<boolean> = [];
  hightlightStatusNewCompany = false;
  selectedCompany: CompaniesData | null = null;

  selectedTaxi: TaxiTicketInfo | null = null;
  selectedPendingTaxi: PendingTaxiTicketinfo | null = null;
  createNewTaxi = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private clerkService: HttpClerkService,
    private tenantService: TenantService
  ) {
    this.tenantId$ = tenantService.chosenTenant.pipe(
      filter(isNotUndefined),
      map((v) => v.tenantId),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.companies = this.tenantId$.pipe(
      switchMap((tenantId) => {
        return this.refreshCompanyList$.pipe(
          switchMap(() => {
            return this.clerkService.listCompanies(tenantId).toPromise();
          }),
          catchError((err) => EMPTY)
        );
      }),
      shareReplay(1)
    );
    this.selectedCompanyId = this.route.params.pipe(map((params) => params.id));
    this.routeSub = combineLatest([
      this.companies,
      this.selectedCompanyId,
    ]).subscribe({
      next: (values) => {
        const [companies, selectedCompanyId] = values;
        this.initCompany(selectedCompanyId, companies);
      },
    });
  }

  ngOnInit() {
    this.refreshCompanyList$.next();
  }

  selectTaxi(which: TaxiTicketInfo) {
    this.selectedTaxi = which;
    this.selectedPendingTaxi = null;
    this.createNewTaxi = false;
  }
  selectPendingTaxi(which: PendingTaxiTicketinfo) {
    console.warn('Companies.component: selectPendingTaxi', which);
    this.selectedPendingTaxi = which;
    this.selectedTaxi = null;
    this.createNewTaxi = false;
  }

  newTaxi(create: boolean) {
    this.selectedTaxi = null;
    this.createNewTaxi = create;
  }

  show(company: CompaniesData, idx: number, setLocation = true) {
    if (this.selectedTaxi || !this.hightlightStatus[idx]) {
      this.hightlightStatus = [];
      this.hightlightStatusNewCompany = false;
      this.hightlightStatus[idx] = true;
      this.selectedCompany = company;
      this.selectedTaxi = null;
      this.createNewTaxi = false;

      if (this.taxiList) {
        this.taxiList.resetTaxiView();
      }

      console.log(company, idx);
      this.location.go('../companies/' + this.selectedCompany.id);
      // this.router.navigate(['../companies', this.selectedCompany.id]);
    } else if (!this.selectedTaxi && this.hightlightStatus[idx]) {
      this.hightlightStatus = [];
      this.hightlightStatusNewCompany = false;
      this.selectedCompany = null;
      this.selectedTaxi = null;
      this.createNewTaxi = false;

      if (this.taxiList) {
        this.taxiList.resetTaxiView();
      }
    }
  }

  newCompany() {
    this.hightlightStatus = [];
    this.hightlightStatusNewCompany = true;
    this.selectedCompany = null;

    this.location.go('../companies/new');
    // this.router.navigate(['../companies', "new"]);
  }

  reset() {
    this.hightlightStatus = [];
    this.hightlightStatusNewCompany = false;
    this.selectedCompany = null;

    this.loadCompanies();
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  loadCompanies() {
    this.createNewTaxi = false;
    this.selectedTaxi = null;
    this.selectedPendingTaxi = null;
    this.refreshCompanyList$.next();
  }

  private initCompany(id: string | undefined, companies: Array<CompaniesData>) {
    if (id === 'new') {
      this.newCompany();
    } else {
      if (this.companies) {
        for (let index = 0; index < companies.length; index++) {
          const company = companies[index];
          if (company.id === id) {
            this.show(company, index);
            break;
          }
        }
      }
    }
  }
}
