<div class="container">
  <div class="row" *ngIf="tenantId$ | async as tenantId">
    <div class="col-sm-6">
      <app-search-user
        (onSelected)="selectUser($event)"
        (onUnSelected)="unselect()"
        [label]="'Nutzersuche'"
        [tenantId]="tenantId"
      ></app-search-user>
    </div>
    <div class="col-sm-6" *ngIf="state$ | async as state">
      <form *ngIf="state.hasSelected">
        <div class="info_text blockedUser" *ngIf="state.selectedUser.blocked">
          Dieser Nutzer ist gesperrt und ist nicht brechtigt den
          FiftyFifty-Dienst zu verwenden!
        </div>

        <div class="form-group row">
          <label for="inputfirstName" class="col-sm-3 col-form-label"
            >Vorname</label
          >
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputfirstName"
              value="{{ state.selectedUser.firstName }}"
              disabled
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="inputlastName" class="col-sm-3 col-form-label"
            >Nachname</label
          >
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputlastName"
              value="{{ state.selectedUser.lastName }}"
              disabled
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="inputDateOfBirth" class="col-sm-3 col-form-label"
            >Geburtstag</label
          >
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputDateOfBirth"
              value="{{ state.selectedUser.dateOfBirth | date: 'dd.MM.yyyy' }}"
              disabled
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="inputemail" class="col-sm-3 col-form-label">E-Mail</label>
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputemail"
              value="{{ state.selectedUser.email }}"
              disabled
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="inputstreet" class="col-sm-3 col-form-label"
            >Straße</label
          >
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputstreet"
              value="{{ state.selectedUser.street }}"
              disabled
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="inputstreetNumber" class="col-sm-3 col-form-label"
            >Hausnummer</label
          >
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputstreetNumber"
              value="{{ state.selectedUser.streetNumber }}"
              disabled
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="inputzipCode" class="col-sm-3 col-form-label">PLZ</label>
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputzipCode"
              value="{{ state.selectedUser.zipCode }}"
              disabled
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="inputcity" class="col-sm-3 col-form-label">Stadt</label>
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputcity"
              value="{{ state.selectedUser.city }}"
              disabled
            />
          </div>
        </div>

        <div class="form-group">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="gridCheck2"
              name="selectedUser.override_age_check"
              [(ngModel)]="state.selectedUser.overrideAgeCheck"
            />
            <label class="form-check-label" for="gridCheck2">
              Altersvalidierung ausschalten
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="gridCheck"
              name="selectedUser.override_residence_check"
              [(ngModel)]="state.selectedUser.overrideResidenceCheck"
            />
            <label class="form-check-label" for="gridCheck">
              Wohnortvalidierung ausschalten
            </label>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 info_text">{{ state.info_msg }}</div>
          <div class="col-sm-5">
            <button
              type="button"
              class="btn btn-info"
              (click)="update(state.selectedUser, tenantId)"
            >
              Update
            </button>
          </div>
          <div class="col-sm-4" *ngIf="!state.selectedUser.blocked">
            <button
              type="button"
              class="btn btn-warning"
              (click)="modal.show()"
            >
              eTicket widerrufen und Nutzer sperren
            </button>
          </div>

          <div class="col-sm-4" *ngIf="state.selectedUser.blocked">
            <button
              type="button"
              class="btn btn-warning"
              (click)="unblock(state.selectedUser, tenantId)"
            >
              Nutzer entsperren
            </button>
          </div>

          <div class="col-sm-12"></div>
          <div class="col-sm-4 offset-md-5">
            <button
              type="button"
              class="btn btn-danger"
              (click)="delete(state.selectedUser, tenantId)"
            >
              Nutzer löschen
            </button>
          </div>
        </div>
      </form>

      <!-- <button type="button" (click)="modal.show()">test</button> -->
      <app-messagebox #modal>
        <div class="app-messagebox-header">
          Nutzer sperren?
        </div>
        <div class="app-messagebox-body">
          Das eTicket des Nutzers wird hierbei gesperrt. Dieser Nutzer ist
          anschließend nicht mehr in der Lage ein neues eTicket zu erhalten. Der
          Nutzer kann nach dem Sperrvorgang auch wieder freigeschaltet werden.
        </div>
        <div class="app-messagebox-footer">
          <div class="row">
            <div class="col-sm-6">
              <button
                type="button"
                class="btn btn-warning"
                (click)="revoke(state.selectedUser, tenantId); modal.hide()"
              >
                Nutzer sperren
              </button>
            </div>
            <div class="col-sm-5">
              <button
                type="button"
                class="btn btn-default"
                (click)="modal.hide()"
              >
                Abbrechen
              </button>
            </div>
          </div>
        </div>
      </app-messagebox>
    </div>
  </div>
</div>
