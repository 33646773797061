<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-12">
          <div *ngFor="let company of companies | async; let i = index">
            <div
              [class.highlight]="hightlightStatus[i]"
              (click)="show(company, i)"
              class="list-group-item"
            >
              {{ company.companyName }}
            </div>
            <div
              *ngIf="hightlightStatus[i]"
              [class.highlightTaxiContainer]="hightlightStatus[i]"
            >
              <app-company-taxi-overview
                [company]="selectedCompany"
                (onSelectTaxi)="selectTaxi($event)"
                (onSelectPendingTaxi)="selectPendingTaxi($event)"
                (onNewTaxi)="newTaxi($event)"
              ></app-company-taxi-overview>
            </div>
          </div>

          <ul class="list-group">
            <li
              [class.highlight]="hightlightStatusNewCompany"
              class="list-group-item new"
              (click)="newCompany()"
            >
              Taxiunternehmen hinzufügen
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="row">
        <div
          class="col-sm-12"
          *ngIf="!selectedTaxi && !createNewTaxi && !selectedPendingTaxi"
          style="max-height: 100%; overflow: auto; overflow-x: hidden;"
        >
          <app-company-detail
            [company]="selectedCompany"
            [newCompany]="hightlightStatusNewCompany"
            [tenantId]="tenantId$ | async"
            (created)="loadCompanies()"
          ></app-company-detail>
        </div>

        <div class="col-sm-12">
          <app-company-pending-taxi-detail
            *ngIf="!createNewTaxi && selectedPendingTaxi"
            [company]="selectedCompany"
            [taxi]="selectedPendingTaxi"
            [tenantId]="tenantId$ | async"
            (onDeleted)="loadCompanies()"
          >
          </app-company-pending-taxi-detail>
          <app-company-taxi-detail
            [company]="selectedCompany"
            [taxi]="selectedTaxi"
            [newTaxi]="createNewTaxi"
            [tenantId]="tenantId$ | async"
            (onCreated)="loadCompanies()"
          ></app-company-taxi-detail>
        </div>
      </div>
    </div>
  </div>
</div>
