<form>
  <div class="form-group row">
    <label for="inputName" class="col-sm-2 col-form-label">{{ label }}</label>
    <div class="col-sm-8">
      <input
        #searchQuery
        type="text"
        class="form-control"
        id="inputSearch"
        name="searchQuery"
        (keyup)="query$.next(searchQuery.value)"
      />
    </div>
  </div>
</form>

<div *ngIf="$foundUsers | async as foundUsers">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <ul class="list-group">
          <li
            *ngFor="let user of foundUsers; let i = index"
            class="list-group-item"
          >
            <form>
              <div class="form-group row clickable" (click)="selectUser(user)">
                <div class="col-sm-10">
                  <div class="col-sm-12">
                    {{ user.firstName }} {{ user.lastName }}
                  </div>

                  <div class="col-sm-12">
                    <div class="col-sm-12">
                      {{ user.street }} {{ user.streetNumber }}
                    </div>
                    <div class="col-sm-12">
                      {{ user.zipCode }} {{ user.city }}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
