import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClerkService } from '@ecsec/ff-taxi-api-ang9';
import { TenantSummary } from '@ecsec/ff-tenant-api-ang9';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { HasTenantIds, HasUserEvaluation } from '../../../tenant.service';

@Component({
  selector: 'app-taxi-detail',
  templateUrl: './taxi-detail.component.html',
  styleUrls: ['./taxi-detail.component.css'],
})
export class TaxiDetailComponent implements OnInit {
  @Input() taxi: any;
  @Input() newTaxi: any;
  @Input() tenantSummary$!: Observable<
    HasUserEvaluation<HasTenantIds<TenantSummary>>
  >;

  @Output() onCreated = new EventEmitter<boolean>();

  nonce: string | null = null;
  licensePlate = '';
  fakeCompanyId = 'FAKE_COMPANY_ID';

  constructor(private taxiClerkService: ClerkService) {}

  ngOnInit(): void {}

  requestNonce(txt: string): void {
    console.log(txt);

    this.tenantSummary$
      .pipe(take(1))
      .toPromise()
      .then((tenant) => {
        const tenantId = tenant.id;
        return this.taxiClerkService
          .requestTaxiRegistration(tenantId, {
            companyId: this.fakeCompanyId,
            licensePlateNumber: txt,
          })
          .toPromise()
          .then((evt) => {
            console.log(evt);
            if (!evt.nonce) {
              throw new Error('Nonce was not returned!');
            }
            this.nonce = window.atob(evt.nonce);
            console.log(this.nonce);
          })
          .catch((evt) => {
            console.log(evt);
          });
      });
  }

  removeTaxi(serial: string): void {
    console.log(serial);

    this.tenantSummary$
      .pipe(take(1))
      .toPromise()
      .then((tenant) => {
        const tenantId = tenant.id;
        return this.taxiClerkService
          .deleteTaxi(serial, tenantId)
          .toPromise()
          .then((evt) => {
            console.log(evt);
            this.finish();
          })
          .catch((evt) => {
            console.log(evt);
          });
      });
  }

  finish(): void {
    this.nonce = null;
    this.licensePlate = '';

    this.onCreated.emit(true);
  }
}
