<div class="container">
  <div class="row">
    <div class="col-md-12">
      <ul class="d-flex flex-wrap row">
        <li
          class="card col-md-4 p-2"
          *ngFor="let tenant of availableTenants | async"
        >
          <img
            [src]="tenant.tenant.icon"
            class="card-img-top"
            [alt]="tenant.tenant.name"
          />
          <div class="card-body">
            <h5 class="card-title">{{ tenant.tenant.name }}</h5>
            <p class="card-text">Wechseln Sie zu: {{ tenant.tenant.name }}</p>
            <button class="btn btn-info" (click)="tenant.choose()">
              Wechseln
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
