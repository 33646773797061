import { Component, Input, OnInit } from '@angular/core';
import {
  Availability,
  IncidentEntry,
  JourneyReportResponse,
  ReportService,
  ValidationResult,
} from '@ecsec/ff-journey-api-ang9';
import * as FileSaver from 'file-saver';
import { EMPTY } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TenantService } from '../../../tenant.service';

function sortAvailability(a: Availability, b: Availability): number {
  const yearComparision = b.year - a.year;
  if (yearComparision !== 0) {
    return yearComparision;
  }
  return b.month - a.month;
}

@Component({
  selector: 'app-report-overview',
  templateUrl: './report-overview.component.html',
  styleUrls: ['./report-overview.component.css'],
})
export class ReportOverviewComponent implements OnInit {
  hightlightStatus: Array<boolean> = [];
  selectedJourney = null;

  journeys: Array<JourneyReportResponse> = [];

  canUpdate = false;
  dataLoaded = false;
  canCreateArchive = false;
  feedbackMsg: string | null = null;

  availableReports: Array<Availability> | undefined;
  selectedReportIdx: number | undefined;
  tenantId!: string;

  constructor(
    private reportService: ReportService,
    private tenantService: TenantService
  ) {}

  ngOnInit() {
    this.tenantService.chosenTenant
      .pipe(
        switchMap((tenantId) => {
          if (tenantId) {
            this.tenantId = tenantId.tenantId;
            return this.reportService
              .listAvailableReportsForTenant(tenantId.tenantId)
              .toPromise();
          } else {
            return EMPTY;
          }
        }),
        catchError((err) => {
          console.error(err);
          return EMPTY;
        })
      )
      .forEach((evt) => {
        console.log(evt);
        this.availableReports = evt;
        if (this.availableReports.length > 0) {
          this.selectedReportIdx = 0;
          this.dataLoaded = true;
          this.canUpdate = true;
          this.feedbackMsg = null;
          this.availableReports.sort(sortAvailability);
        }
      });
  }

  changeReport(idx: number) {
    this.selectedReportIdx = idx;
  }

  downloadArchive(idx: number | undefined) {
    if (!this.availableReports || idx === undefined) {
      this.feedbackMsg = 'Keine Archive vorhanden...';
    } else {
      this.feedbackMsg = 'Das Archiv wird geladen, bitte warten ...';
      const selectedReport = this.availableReports[idx];
      this.reportService
        .getReportArchiveForPeriodAndTenant(
          this.tenantId,
          selectedReport.month,
          selectedReport.year,
          'body'
        )
        .toPromise()
        .then((blob: any) => {
          console.log(blob);
          this.feedbackMsg = null;
          FileSaver.saveAs(
            blob,
            '' +
              selectedReport.year +
              (selectedReport.month < 10 ? '0' : '') +
              selectedReport.month +
              '_FiftyFifty-Report.zip'
          );
        })
        .catch((evt: any) => {
          console.log(evt);
          this.feedbackMsg = 'Es ist ein unerwarteter fehler aufgetreten!';
          setTimeout(() => {
            this.changeReport(idx);
            this.dataLoaded = true;
            this.canUpdate = true;
            this.feedbackMsg = null;
          }, 5000);
        });
    }
  }
}
